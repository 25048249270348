import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import "../Table/Table.css";
import { API_URL } from "../../constants";

const TablePatient = ({ patients }) => {
  return (
    <div>
      <TableContainer
        style={{
          boxShadow: "0px 13px 20px 0px #80808029",
          maxHeight: "500px",
          overflowY: "auto",
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom du patient</TableCell>
              <TableCell align="left">Date de naissance</TableCell>
              <TableCell align="left">Adresse</TableCell>
              <TableCell align="left">Numéro de téléphone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient) => {
              const imageURL = patient.image
                ? `${API_URL}${patient.image}`
                : "https://picsum.photos/300/200";
              return (
                <TableRow
                  key={patient.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div className="list-rdv-medecins">
                      <img
                        src={imageURL}
                        alt="patient"
                        style={{ width: 50, height: 50, borderRadius: "50%" }}
                      />
                      <h3 class="list-rdv-nom">{`${patient.nom} ${patient.prenom}`}</h3>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {dayjs(patient.dateNaissance).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell align="left">{patient.adresse}</TableCell>
                  <TableCell align="left">{patient.telephone}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablePatient;
