import React from "react";
import Navbar from "./Navbar";
import Search from "./Search";
import Conversations from "./Conversations";

const Sidebar = ({ userType, onSelectUser }) => {
  return (
    <div className="chat-sidebar">
      <Navbar userType={userType} />
      <Search userType={userType} onSelectUser={onSelectUser} />
      <Conversations onSelectUser={onSelectUser} />{" "}
    </div>
  );
};

export default Sidebar;
