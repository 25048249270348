import React from "react";
import "../components/CardHistorique.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons";

const CardHistorique = ({ rdv, isSelected }) => {
  return (
    <div>
      <div className="card-historique">
        <div
          className="card-historique-container"
          style={{
            backgroundColor: isSelected ? "#024631" : "#94b838e0",
          }}
        >
          <div className="steto">
            <FontAwesomeIcon icon={faStethoscope} />
            <h3 className="h3-steto">Consultation</h3>
          </div>

          <p>Motif : {rdv.motif}</p>
          <p>Mode : {rdv.mode}</p>
        </div>
      </div>
    </div>
  );
};

export default CardHistorique;
