import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import "../styles/componentStyle/timePicker.css"; // Importez le fichier CSS

export default function BasicTimePicker({ label, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          label={label}
          onChange={onChange}
          value={value ? dayjs(value) : null}
          ampm={false} // Utiliser le format 24 heures
          renderInput={(params) => <TextField {...params} />}
          minutesStep={30} // Intervalles de 30 minutes
          format="HH:mm" // Format d'affichage
          className="custom-time-picker"
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
