import React, { useState, useEffect } from "react";
import "../../styles/componentStyle/availabilities.css";
import DaysList from "../Data/DaysList";
import ControlledCheckbox from "../Checkbox";
import BasicTimePicker from "../TimePicker";
import Button from "../Button";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import dayjs from "dayjs";
import { API_URL } from "../../constants";

registerLocale("fr", fr);

const Availability = () => {
  const [availabilities, setAvailabilities] = useState({});
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));
  const token = localStorage.getItem("token");
  const medecinId = medecin.id;

  useEffect(() => {
    const fetchDisponibilites = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/medecin/disponibilites/${medecinId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedDisponibilites = response.data.disponibilite;

        const formattedDisponibilites = fetchedDisponibilites.reduce(
          (acc, curr) => {
            const { id, jour, heureDebut, heureFin } = curr;
            if (!acc[jour]) acc[jour] = [];
            acc[jour].push({
              id, // Inclure l'id pour permettre la suppet la modif
              startTime: dayjs(`1970-01-01T${heureDebut}`).toDate(),
              endTime: dayjs(`1970-01-01T${heureFin}`).toDate(),
            });
            return acc;
          },
          {}
        );

        setAvailabilities(formattedDisponibilites);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des disponibilités",
          error
        );
      }
    };

    fetchDisponibilites();
  }, [medecinId]);

  const handleDayChange = (day) => {
    setAvailabilities((prev) => {
      const newState = { ...prev };
      if (newState[day]) {
        delete newState[day];
      } else {
        newState[day] = [];
      }
      return newState;
    });
  };

  const handleAddSlot = (day) => {
    setAvailabilities((prev) => ({
      ...prev,
      [day]: [
        ...(prev[day] || []),
        { id: null, startTime: null, endTime: null },
      ],
    }));
  };

  const handleSlotChange = (day, index, type, value) => {
    setAvailabilities((prev) => {
      const newSlots = [...prev[day]];
      newSlots[index][type] = value;
      return { ...prev, [day]: newSlots };
    });
  };

  const handleDeleteSlot = async (day, index) => {
    const slot = availabilities[day][index];
    if (slot.id) {
      try {
        await axios.delete(`${API_URL}/medecin/disponibilites/${slot.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de la disponibilité",
          error
        );
        return;
      }
    }
    setAvailabilities((prev) => {
      const newSlots = [...prev[day]];
      newSlots.splice(index, 1);
      return { ...prev, [day]: newSlots };
    });
  };

  const handleUpdateSlot = async (day, index) => {
    const slot = availabilities[day][index];
    if (slot.id) {
      try {
        await axios.put(
          `${API_URL}/medecin/disponibilites/${slot.id}`,
          {
            disponibilites: {
              medecinId,
              jour: day,
              heureDebut: dayjs(slot.startTime).format("HH:mm"),
              heureFin: dayjs(slot.endTime).format("HH:mm"),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        window.alert("Créneau mis à jour avec succès");
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la disponibilité",
          error
        );
      }
    }
  };

  const handleSaveDisponibilites = async () => {
    const newDisponibilites = [];
    const existingDisponibilites = [];

    Object.keys(availabilities).forEach((day) => {
      availabilities[day].forEach((slot) => {
        const formattedSlot = {
          medecinId,
          jour: day,
          heureDebut: slot.startTime
            ? dayjs(slot.startTime).format("HH:mm")
            : "",
          heureFin: slot.endTime ? dayjs(slot.endTime).format("HH:mm") : "",
        };

        if (slot.id) {
          existingDisponibilites.push({ id: slot.id, ...formattedSlot });
        } else {
          newDisponibilites.push(formattedSlot);
        }
      });
    });

    try {
      // Pour les nouvelles disponibilités
      if (newDisponibilites.length > 0) {
        await axios.post(
          `${API_URL}/medecin/disponibilites`,
          { disponibilites: newDisponibilites },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      // Pour les disponibilités existantes
      if (existingDisponibilites.length > 0) {
        await Promise.all(
          existingDisponibilites.map((slot) =>
            axios.put(
              `${API_URL}/medecin/disponibilites/${slot.id}`,
              { disponibilites: slot },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
          )
        );
      }

      console.log("Disponibilités enregistrées avec succès");
      window.alert("Disponibilités mises à jour avec succès");
    } catch (error) {
      console.error(
        "Erreur lors de l'enregistrement des disponibilités",
        error
      );
    }
  };

  return (
    <div className="p-10">
      <h2 className="availability-text">Disponibilités</h2>
      <hr />
      <h4 className="intro-dr-av">
        <br></br>
        Dr, {medecin.nom} {medecin.prenom},
      </h4>
      <p className="intro-av">
        Gérez vos disponibilités hebdomadaires pour une planification optimale
        des rendez-vous avec vos patients en ajoutant des créneaux horaires
        précis jour par jour
      </p>
      <br></br>
      <div className="custom-grid">
        {DaysList.map((item, index) => (
          <div key={index}>
            <h2 className="day-item">
              <ControlledCheckbox
                checked={!!availabilities[item.day]}
                onChange={() => handleDayChange(item.day)}
              />
              {item.day}
              {availabilities[item.day] && (
                <>
                  {availabilities[item.day].map((slot, slotIndex) => (
                    <div className="time" key={slotIndex}>
                      <BasicTimePicker
                        label={"Heure de début"}
                        value={slot.startTime}
                        onChange={(newValue) =>
                          handleSlotChange(
                            item.day,
                            slotIndex,
                            "startTime",
                            newValue
                          )
                        }
                      />
                      <BasicTimePicker
                        label={"Heure de fin"}
                        value={slot.endTime}
                        onChange={(newValue) =>
                          handleSlotChange(
                            item.day,
                            slotIndex,
                            "endTime",
                            newValue
                          )
                        }
                      />
                      <div className="btn-delete">
                        <button
                          className="delete-btn"
                          onClick={() => handleDeleteSlot(item.day, slotIndex)}
                        >
                          Supprimer
                        </button>
                        <button
                          className="update-btn"
                          onClick={() => handleUpdateSlot(item.day, slotIndex)}
                        >
                          Modifier
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="slot-add">
                    <button
                      className="add-btn"
                      onClick={() => handleAddSlot(item.day)}
                    >
                      Ajouter
                    </button>
                  </div>
                </>
              )}
            </h2>
          </div>
        ))}
      </div>
      <div className="btn-av">
        <Button
          btnText={"Valider"}
          type={"submit"}
          onClick={handleSaveDisponibilites}
          customClass="valider-btn"
        />
      </div>
    </div>
  );
};

export default Availability;
