import React from "react";
import MenuVisio from "./MenuVisio";

const OtherSideVisioMedecin = () => {
  return (
    <div>
      <MenuVisio showPrescription={true} />
    </div>
  );
};

export default OtherSideVisioMedecin;
