import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../styles/componentStyle/Stepper.css";
import MotifForm from "./PriseDeRendezVous/MotifForm";
import MeetingSchedule from "./PriseDeRendezVous/MeetingSchedule";
import Identification from "./PriseDeRendezVous/Identification";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";

export default function HorizontalNonLinearStepper() {
  const { id: medecinId } = useParams();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [form, setForm] = React.useState({
    motif: "premierefois",
    mode: "Video",
    heure: null,
    date: dayjs(),
    medecinId,
  });

  const steps = [
    {
      label: "Choix du motif",
      component: <MotifForm form={form} setForm={setForm} />,
    },
    {
      label: "Choix du creneau horaire",
      component: <MeetingSchedule form={form} setForm={setForm} />,
    },
    { label: "Confirmation", component: <Identification form={form} /> },
  ];

  const handleNext = () => {
    if (activeStep === 1) {
      if (!form.heure) {
        toast.error("Veuillez choisir un créneau horaire");
        return;
      }
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const token = sessionStorage.getItem("token");
    axios.post(
      `${API_URL}/patient/rendezvous`,
      {
        ...form,
        date: form.date.format("YYYY/MM/DD"),
        mode: form.mode.toLowerCase(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    window.alert("Rendez-vous réservé avec succès !");
    navigate("/patient/mesrendezvous");
  };

  return (
    <Box sx={{ marginLeft: "150px", width: "80%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          "& .MuiStepIcon-root": {
            color: "rgba(0, 0, 0, 0.54)",
            "&.Mui-active": {
              color: "#024732",
            },
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} sx={{ color: "#024732" }}>
            <StepButton onClick={handleStep(index)}>{step.label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>{steps[activeStep].component}</div>
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
          Etape {activeStep + 1}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="outlined"
            style={{ color: "#024732", borderColor: "#024732" }}
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Retour
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === 2 ? (
            <Button
              onClick={handleComplete}
              style={{
                color: "#024732",
                borderColor: "#024732",
                border: "solid 2px",
              }}
              variant="outlined"
            >
              Valider
            </Button>
          ) : (
            <Button
              onClick={handleNext}
              sx={{ mr: 1 }}
              style={{ color: "white", backgroundColor: "#024732" }}
              variant="contained"
            >
              Suivant
            </Button>
          )}
        </Box>
      </React.Fragment>
      <ToastContainer />
    </Box>
  );
}
