import React from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import { green } from "@mui/material/colors";

const EditButton = ({ onClick }) => {
  const buttonStyle = {
    color: green[500],
    borderColor: green[500],
    "&:hover": {
      borderColor: green[700],
    },
  };

  const iconStyle = {
    color: green[500],
  };

  return (
    <div className="del-edit-btns">
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          startIcon={<EditIcon sx={iconStyle} />}
          sx={buttonStyle}
          onClick={onClick}
        >
          Modifier
        </Button>
      </Stack>
    </div>
  );
};

export default EditButton;
