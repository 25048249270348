import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyle/MenuVisio.css";
import DocMedecin from "./MenuVisioMedecin/DocMedecin";
import DocPatient from "./MenuVisioPatient/DocPatient";
import PrescriptionPatient from "./MenuVisioPatient/PrescriptionPatient";
import ObservationMed from "./MenuVisioMedecin/ObservationMed";
import PrescriptionMed from "./MenuVisioMedecin/PrescriptionMed";
import BilanMedecin from "./MenuVisioMedecin/BilanMedecin";
import BilanPatient from "./MenuVisioPatient/BilanPatient";

const MenuVisio = ({ showPrescription }) => {
  const [activeLink, setActiveLink] = useState(1);
  const userType = sessionStorage.getItem("userType");

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  return (
    <div>
      <nav className="menuvisio-container">
        <ul className="menuvisio-list">
          <li>
            <Link
              className={
                activeLink === 1 ? "active-link" : "menuvisio-list-item"
              }
              onClick={() => {
                handleLinkClick(1);
              }}
            >
              Documents
            </Link>
          </li>
          {showPrescription && (
            <li>
              <Link
                className={
                  activeLink === 2 ? "active-link" : "menuvisio-list-item"
                }
                onClick={() => {
                  handleLinkClick(2);
                }}
              >
                Observations
              </Link>
            </li>
          )}
          <li>
            <Link
              className={
                activeLink === 3 ? "active-link" : "menuvisio-list-item"
              }
              onClick={() => {
                handleLinkClick(3);
              }}
            >
              Ordonnance
            </Link>
          </li>
          <li>
            <Link
              className={
                activeLink === 4 ? "active-link" : "menuvisio-list-item"
              }
              onClick={() => {
                handleLinkClick(4);
              }}
            >
              Bilan
            </Link>
          </li>
        </ul>
      </nav>

      <div className="content">
        {activeLink === 1 &&
          (userType === "medecin" ? <DocMedecin /> : <DocPatient />)}
        {activeLink === 2 &&
          (userType === "medecin" ? <ObservationMed /> : <ObservationMed />)}
        {activeLink === 3 &&
          (userType === "medecin" ? (
            <PrescriptionMed />
          ) : (
            <PrescriptionPatient />
          ))}
        {activeLink === 4 &&
          (userType === "medecin" ? <BilanMedecin /> : <BilanPatient />)}
      </div>
    </div>
  );
};

export default MenuVisio;
