import React, { useState, useEffect } from "react";
import "./Search.css";
import axios from "axios";
import { API_URL } from "../../constants";

const Search = ({ userType, onSelectUser }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (query.length === 0) {
      setResults([]);
      return;
    }

    const handleSearch = async () => {
      console.log(userType);
      const token = sessionStorage.getItem("token");
      try {
        const response = await axios.get(
          `${API_URL}/${
            userType === "medecin"
              ? `patient/search/patients?query=${query}`
              : `medecin/search/medecins?query=${query}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = setResults(response.data);
        console.log("les resultats de la recherche sont", result);
      } catch (error) {
        console.error("Error fetching search results : ", error);
      }
    };

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        handleSearch();
      }, 300)
    );
  }, [query]);

  return (
    <div className="search-chat">
      <div className="searchForm">
        <input
          className="searchForm-input"
          type="text"
          value={query}
          placeholder={`Rechercher ${
            userType === "patient" ? "un médecin" : "un patient"
          }`}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>

      <ul>
        {results.map((user) => (
          <li key={user._id} onClick={() => onSelectUser(user)}>
            <div className="searchUser">
              <img
                className="user-img-chat"
                src={
                  user.image
                    ? `${API_URL}${user.image}`
                    : "https://picsum.photos/300/200"
                }
                alt="img"
              />
              <div className="userChatInfo">
                <span>{user.nom}</span>
                <span>{user.prenom}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Search;
