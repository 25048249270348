import React, { useRef } from "react";
import "./Prescription.css";
import axios from "axios";
import dayjs from "dayjs";
import Barcode from "react-barcode";
import html2PDF from "jspdf-html2canvas";
import { API_URL } from "../../constants";
import "./BilanMedecin.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BilanMedecin = () => {
  const medecinString = sessionStorage.getItem("medecin");
  const medecin = JSON.parse(medecinString);
  const currentDate = dayjs().format("DD/MM/YYYY");
  const pdfRef = useRef();
  const token = sessionStorage.getItem("token");
  const rendezVousId = sessionStorage.getItem("currentRendezVousId");

  const generatePDF = async () => {
    const input = pdfRef.current;

    const res = await html2PDF(input);
    const content = res.output("blob");

    const file = new File([content], "prescription.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("rendezVousId", rendezVousId);

    await axios.post(`${API_URL}/medecin/bilan/${rendezVousId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const downloadPDF = () => {
    generatePDF();
  };

  const sendPDF = () => {
    if (!rendezVousId || !token) {
      console.error("Missing  rdv", rendezVousId);

      return;
    }
    generatePDF();
    toast.success("Bilan envoyée avec succés !");
  };
  return (
    <>
      <div className="container-ordonnance" ref={pdfRef}>
        <div className="entete-medecin-ord">
          <p className="item-medecin-ord">{`Dr ${medecin.nom} ${medecin.prenom} `}</p>
          <p className="item-medecin-ord">{`Spécialité : ${medecin.specialite}  `}</p>
          <p className="item-medecin-ord">{`Adresse du cabinet : ${medecin.adresse}  `}</p>
          <p className="item-medecin-ord">{`Téléphone : ${medecin.telephone}  `}</p>
          <br></br>
          <h1 className="title-ord ">Bilan à faire</h1>
          <br></br>
          <div className="entete-patient-ord">
            <div className="left-patient">
              <div className="input-ord">
                <p>Nom: </p>
                <input type="text" placeholder="nom du patient"></input>
              </div>
              <div className="input-ord">
                <p>Prénom: </p>
                <input type="text" placeholder="prénom du patient"></input>
              </div>
            </div>
            <div className="right-patient">
              <div className="input-ord">
                <p>Date:</p>
                <input type="text" value={currentDate} readOnly></input>
              </div>
              <div className="input-ord">
                <p>Age: </p>
                <input type="text" placeholder="âge du patient"></input>
              </div>
            </div>
          </div>

          <br></br>

          <div className="bilan">
            <h4>Priere de faire :</h4>
            <br></br>
            <div className="item-bilan">
              <div className="item-bilan-left">
                <input type="checkbox"></input>
                <label> FNS</label>
                <br></br>
                <input type="checkbox"></input>
                <label> VS</label>
                <br></br>
                <input type="checkbox"></input>
                <label> CRP</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Glycemie a jeun</label>
                <br></br>
                <input type="checkbox"></input>
                <label> HbA1c</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Uree</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Creatinine</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Acide urique</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Calcemie</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Phosophremie</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Cholesterol total</label>
                <br></br>
                <input type="checkbox"></input>
                <label> LDL-ch/ HDL-ch</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Triglycerides</label>
                <br></br>
                <input type="checkbox"></input>
                <label> ASAT-ALAT</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Lonogramme sanguin</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Micro-Albuminurie des 24h</label>
                <br></br>
              </div>
              <div className="item-bilan-right">
                <input type="checkbox"></input>
                <label> CPK</label>
                <br></br>
                <input type="checkbox"></input>
                <label> TSH</label>
                <br></br>
                <input type="checkbox"></input>
                <label> FT3</label>
                <br></br>
                <input type="checkbox"></input>
                <label> FT4</label>
                <br></br>
                <input type="checkbox"></input>
                <label> Ac anti TPO</label>
                <br></br>
                <input type="checkbox"></input>
                <label> PTH</label>
                <br></br>
                <br></br>
                <input type="checkbox"></input>
                <label> Autres : ....</label>
                <br></br>
              </div>
            </div>
          </div>
          <div className="barcode-container">
            <Barcode value="1234567890" className="barcode" />
          </div>
        </div>
      </div>
      <div className="btns-downsend">
        <button className="btn-down-send" onClick={sendPDF}>
          Envoyer
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default BilanMedecin;
