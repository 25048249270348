import video from "../../assets/icons/video.png";
import call from "../../assets/icons/call.png";
import home from "../../assets/icons/home.png";

const LocationOption = [
  {
    name: "Video",
    icon: video,
  },
  {
    name: "Appel",
    icon: call,
  },
  {
    name: "Cabinet",
    icon: home,
  },
];

export default LocationOption;
