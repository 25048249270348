import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { API_URL } from "../../constants";

const PrescriptionPatient = () => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const retrieveOrdonnance = async () => {
    const token = sessionStorage.getItem("token");
    const rendezVousId = sessionStorage.getItem("currentRendezVousId");
    try {
      const response = await axios.get(
        `${API_URL}/patient/ordonnance/${rendezVousId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && response.data.file) {
        setPdfUrl(`${API_URL}${response.data.file}`);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du fichier", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      retrieveOrdonnance();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                title="Ordonnance"
                width="100%"
                height="600px"
              />
            ) : (
              <p>Chargement de l'ordonnance...</p>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PrescriptionPatient;
