import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ObservationMed.css";
import { API_URL } from "../../constants";

const ObservationMed = ({ showSave = true }) => {
  const [motif, setMotif] = useState("");
  const [interrogatoire, setInterrogatoire] = useState("");
  const [age, setAge] = useState("");
  const [poids, setPoids] = useState("");
  const [taille, setTaille] = useState("");
  const [grpSang, setGrpSang] = useState("");
  const [antecedentPerso, setAntecedentPerso] = useState("");
  const [antecedentsFami, setAntecedentsFami] = useState("");
  const [conclusion, setConclusion] = useState("");

  const handleSaveNotes = async () => {
    const token = sessionStorage.getItem("token");
    const rendezVousId = sessionStorage.getItem("currentRendezVousId");

    try {
      const response = await axios.post(
        `${API_URL}/medecin/noterendezvous/${rendezVousId}`,
        {
          motif,
          interrogatoire,
          age,
          poids,
          taille,
          grpSang,
          antecedentPerso,
          antecedentsFami,
          conclusion,
        },
        {
          headers: {
            ContentType: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      toast.success("Notes sauvegardé avec succès !");
    } catch (error) {
      console.error("Error saving notes:", error);
      toast.error("Erreur lors de la sauvegarde des notes.");
    }
  };
  return (
    <div>
      <h4 className="observation-title">Notes cliniques :</h4>
      <br />
      <div>
        <div className="observation-medical-container">
          <div>
            <p className="observation-item">Motif</p>
            <textarea
              className="txt-size"
              placeholder="Hypoglycémie depuis 2 jours par exp"
              value={motif}
              onChange={(e) => setMotif(e.target.value)}
            />
            <p className="observation-item">Interrogatoire </p>
            <textarea
              className="txt-size-interro"
              placeholder="Histoire de la maladie, début des symptômes, leur évolution , traitement déjà essayé ..."
              value={interrogatoire}
              onChange={(e) => setInterrogatoire(e.target.value)}
            />
            <p className="observation-item">Données de suivi</p>
            <div className="obser-inputs">
              <input
                className="input-size"
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
              <input
                className="input-size"
                placeholder="Poids (Kg)"
                value={poids}
                onChange={(e) => setPoids(e.target.value)}
              />
              <input
                className="input-size"
                placeholder="Taille (m)"
                value={taille}
                onChange={(e) => setTaille(e.target.value)}
              />
              <input
                className="input-size"
                placeholder="Groupe Sanguin"
                value={grpSang}
                onChange={(e) => setGrpSang(e.target.value)}
              />
            </div>

            <p className="observation-item">Antécédents personnels</p>
            <textarea
              className="txt-size-ant"
              placeholder="Antécédents médicaux et chirurgicaux "
              value={antecedentPerso}
              onChange={(e) => setAntecedentPerso(e.target.value)}
            />
            <p className="observation-item">Antécédents familiaux</p>
            <textarea
              className="txt-size-ant"
              placeholder="Antécédents médicaux et chirurgicaux "
              value={antecedentsFami}
              onChange={(e) => setAntecedentsFami(e.target.value)}
            />
            <p className="observation-item">Conclusion</p>
            <textarea
              className="txt-size-conclusion"
              placeholder="Diagnostic final"
              value={conclusion}
              onChange={(e) => setConclusion(e.target.value)}
            />
            <br />
            {showSave && (
              <div className="btn-obs-container">
                <button className="btn-observation" onClick={handleSaveNotes}>
                  Sauvegarder
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ObservationMed;
