import React from "react";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import logo from "../assets/images/LogoDark.png";
import "../styles/componentStyle/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="footer-social">
          <a
            href="https://github.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/example"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://www.instagram.com/example"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaInstagram />
          </a>
        </div>
        <div className="footer-info">
          <p>&copy; {new Date().getFullYear()} Careline App</p>
          <p>Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
