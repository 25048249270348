import React, { useEffect } from "react";
import FormMaker from "../../components/FormMaker.js";
import logo from "../../assets/images/Logo.png";
import "../../styles/pageStyle/InscriptionMedecin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faStethoscope,
  faMapMarkerAlt,
  faPhone,
  faKey,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants.js";

const InscriptionMedecin = ({ handleLogin }) => {
  const Navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("signup-page");

    return () => {
      document.body.classList.remove("signup-page");
    };
  }, []);

  const Notify = (message, callback) => {
    toast.success(message, {
      onClose: callback,
    });
  };

  async function handleSubmit(values) {
    const {
      nom,
      prenom,
      specialite,
      email,
      adresse,
      telephone,
      password,
      cpassword,
      image,
    } = values;

    try {
      if (
        !nom ||
        !prenom ||
        !specialite ||
        !email ||
        !adresse ||
        !telephone ||
        !password ||
        !cpassword
      ) {
        throw new Error("Veuillez remplir tous les champs");
      }

      if (password !== cpassword) {
        toast.error("Les mots de passe ne correspondent pas.");
        return;
      }

      const formData = new FormData();
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("specialite", specialite);
      formData.append("adresse", adresse);
      formData.append("email", email);
      formData.append("telephone", telephone);
      formData.append("password", password);
      formData.append("image", image);

      const response = await axios.post(
        `${API_URL}/medecin/inscription/medecin`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const token = response.data.token;
      localStorage.setItem("token", token);
      const userData = response.data;
      sessionStorage.setItem("token", userData.token); //optionnel
      sessionStorage.setItem("medecin", JSON.stringify(userData.medecin));

      handleLogin();
      toast.success("Inscription réussie !");
      setTimeout(() => {
        Navigate("/medecin");
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  }

  return (
    <div className="form">
      <FormMaker
        logo={<img src={logo} alt="logo" className="logo-form" />}
        headline={"Nouveau sur Careline ?"}
        subHeadline={"Saisissez vos informations pour continuer."}
        onSubmit={handleSubmit}
        inputs={[
          [
            {
              icon: <FontAwesomeIcon icon={faUser} />,
              name: "nom",
              label: "Nom",
              type: "text",
            },
            {
              icon: <FontAwesomeIcon icon={faUser} />,
              name: "prenom",
              label: "Prénom",
              type: "text",
            },
          ],
          {
            icon: <FontAwesomeIcon icon={faStethoscope} />,
            name: "specialite",
            label: "Spécialité",
            type: "text",
          },
          {
            icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
            name: "adresse",
            label: "Adresse",
            type: "text",
          },
          {
            icon: <FontAwesomeIcon icon={faMailBulk} />,
            name: "email",
            label: "Email",
            type: "email",
          },
          {
            icon: <FontAwesomeIcon icon={faPhone} />,
            name: "telephone",
            label: "Numéro de téléphone",
            type: "text",
          },
          {
            icon: <FontAwesomeIcon icon={faKey} />,
            name: "password",
            label: "Mot de passe",
            type: "password",
          },
          {
            icon: <FontAwesomeIcon icon={faKey} />,
            name: "cpassword",
            label: "Confirmer Mot de passe",
            type: "password",
          },
          {
            name: "image",
            type: "file",
          },
          {
            name: "loginLink",
            label: "Vous avez deja un compte ? Connectez-vous",
            link: "/authentification/medecin",
            type: "link",
          },
        ]}
        onSubmitLabel={"S'inscrire"}
      />
    </div>
  );
};

export default InscriptionMedecin;
