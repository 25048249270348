import { useEffect, useState } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";

const socket = io(API_URL);

async function getRendezvous(id) {
  const token = sessionStorage.getItem("token");
  return await axios.get(`${API_URL}/medecin/visiocall/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

async function updateRendezvous(id, data) {
  const token = sessionStorage.getItem("token");
  return await axios.patch(`${API_URL}/medecin/rendezVous/visioInfo/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const useVisioconference = () => {
  const { rendezVousId } = useParams();
  const userType = sessionStorage.getItem("userType");
  const { id } = JSON.parse(sessionStorage.getItem(userType));
  const [localStream, setLocalStream] = useState();
  const [remoteStream, setRemoteStream] = useState();

  let onDestroy = () => null;

  async function init() {
    const {
      data: { visioInfo, patientId, medecinId },
    } = await getRendezvous(rendezVousId);

    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    const pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    });

    stream.getTracks().forEach((track) => {
      pc.addTrack(track, stream);
    });

    pc.ontrack = (event) => {
      if (event.streams && event.streams.length > 0) {
        setRemoteStream(event.streams[0]);
      }
    };

    setLocalStream(stream);

    console.log("User type", userType);

    if (userType === "medecin") {
      console.log("Creating offer.");
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      const ice = [];

      pc.onicecandidate = (e) => e.candidate && ice.push(e.candidate);

      setTimeout(async () => {
        await updateRendezvous(rendezVousId, {
          [id]: {
            sdp: offer.sdp,
            type: offer.type,
            ice,
          },
        });
      }, 3000);

      socket.on(`visio-info-${rendezVousId}`, ({ data }) => {
        const [remoteInfo] = Object.entries(data)
          .filter(([key, value]) => key !== id)
          .map(([key, value]) => value);

        if (remoteInfo) {
          pc.setRemoteDescription({ sdp: remoteInfo.sdp, type: remoteInfo.type });

          remoteInfo.ice.forEach((ice) => {
            pc.addIceCandidate(new RTCIceCandidate(ice));
          });
        }
      });
    } else {
      console.log("Creating Answer.");
      const [remoteInfo] = Object.values(visioInfo);

      pc.setRemoteDescription({ sdp: remoteInfo.sdp, type: remoteInfo.type });

      remoteInfo.ice.forEach((ice) => {
        pc.addIceCandidate(new RTCIceCandidate(ice));
      });

      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);

      const ice = [];

      pc.onicecandidate = (e) => e.candidate && ice.push(e.candidate);

      setTimeout(async () => {
        await updateRendezvous(rendezVousId, {
          ...visioInfo,
          [id]: {
            sdp: answer.sdp,
            type: answer.type,
            ice,
          },
        });
      }, 3000);
    }

    onDestroy = () => {
      stream.getTracks().forEach((track) => track.stop());

      if (pc) {
        pc.close();
      }

      if (socket) {
        socket.disconnect();
      }
    };
  }

  useEffect(() => {
    init();
    return () => {
      onDestroy();
    };
  }, []);

  return [localStream, remoteStream];
};

export default useVisioconference;
