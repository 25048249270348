import React from "react";
import "./NoChat.css"; // Add styles if needed
import noMsg from "../../assets/icons/noMsg.png";

const NoChat = () => {
  return (
    <div className="no-chat-grid">
      <div className="chat-flex">
        <img src={noMsg} alt="jkj" width={"500px"}></img> <br></br>
        <p className="noChat-text">
          Sélectionner un utilisateur avec qui vous souhaitez discuter en
          utilisant la barre de recherche.
        </p>
      </div>
    </div>
  );
};

export default NoChat;
