import React, { useEffect, useState } from "react";
import "./Chat.css";
import Messages from "./Messages";
import InputChat from "./InputChat";
import { io } from "socket.io-client";
import axios from "axios";
import { API_URL } from "../../constants";

const socket = io(API_URL);

const Chat = ({ user }) => {
  const [messages, setMessages] = useState([]);
  const userId = user ? user.id : null;
  const userType = sessionStorage.getItem("userType"); // Get the current user type

  // Function to get the senderId from sessionStorage
  const getSenderId = () => {
    const userData = JSON.parse(sessionStorage.getItem(userType));
    return userData ? userData.id : null;
  };

  useEffect(() => {
    if (userId) {
      const joinChat = async () => {
        const token = sessionStorage.getItem("token");

        const res = await axios
          .get(`${API_URL}/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => console.log(err));

        setMessages(res.data.messages);

        try {
          await axios.post(
            `${API_URL}/users/join`,
            { userId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          socket.emit("join-chat", { userId });
        } catch (error) {
          console.error("Error joining chat room:", error);
        }
      };

      joinChat();

      socket.on("receive-message", ({ receiverId, message }) => {
        console.log(receiverId, message, getSenderId());
        if (receiverId === getSenderId()) {
          setMessages((prevMessages) => [...prevMessages, message]);
        }
      });

      return () => {
        socket.off("receive-message");
      };
    }
  }, [userId]);

  const handleSendMessage = (message) => {
    const senderId = getSenderId();
    const receiverId = userId;
    setMessages((prevMessages) => [
      ...prevMessages,
      { senderId, receiverId, content: message },
    ]);
    socket.emit("send-message", { senderId, receiverId, message });
  };

  return (
    <div className="chat">
      <div className="chatInfo">
        <span className="chat-span-header">
          {user
            ? `${user.nom} ${user.prenom}`
            : "Rechercher un utilisateur avec qui vous souhaitez disctuer !"}
        </span>
      </div>
      <Messages user={user} messages={messages} />
      <InputChat onSendMessage={handleSendMessage} />
    </div>
  );
};

export default Chat;
