import React, { useState, useRef } from "react";
import "./Prescription.css";
import dayjs from "dayjs";
import Barcode from "react-barcode";
import axios from "axios";
import html2PDF from "jspdf-html2canvas";
import { API_URL } from "../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PrescriptionMed = () => {
  const medecinString = sessionStorage.getItem("medecin");
  const medecin = JSON.parse(medecinString);
  const currentDate = dayjs().format("DD/MM/YYYY");
  const pdfRef = useRef();
  const token = sessionStorage.getItem("token");
  const rendezVousId = sessionStorage.getItem("currentRendezVousId");

  const [medications, setMedications] = useState([
    { name: "", dosage: "", duration: "" },
  ]);

  const handleInputChange = (index, field, value) => {
    const newMedications = [...medications];
    newMedications[index][field] = value;
    setMedications(newMedications);
  };

  const addMedication = () => {
    setMedications([...medications, { name: "", dosage: "", duration: "" }]);
  };

  const generatePDF = async () => {
    const input = pdfRef.current;

    const res = await html2PDF(input);
    const content = res.output("blob");

    const file = new File([content], "prescription.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("rendezVousId", rendezVousId);

    await axios.post(
      `${API_URL}/medecin/ordonnance/${rendezVousId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const downloadPDF = () => {
    generatePDF();
  };

  const sendPDF = () => {
    if (!rendezVousId || !token) {
      console.error("Missing  rdv", rendezVousId);

      return;
    }
    generatePDF();
    toast.success("Ordonnance envoyée avec succés !");
  };

  return (
    <>
      <div className="container-ordonnance" ref={pdfRef}>
        <div className="entete-medecin-ord">
          <p className="item-medecin-ord">{`Dr ${medecin.nom} ${medecin.prenom} `}</p>
          <p className="item-medecin-ord">{`Spécialité : ${medecin.specialite}  `}</p>
          <p className="item-medecin-ord">{`Adresse du cabinet : ${medecin.adresse}  `}</p>
          <p className="item-medecin-ord">{`Téléphone : ${medecin.telephone}  `}</p>
          <br></br>
          <h1 className="title-ord ">Ordonnance</h1>
          <br></br>
          <div className="entete-patient-ord">
            <div className="left-patient">
              <div className="input-ord">
                <p>Nom: </p>
                <input type="text" placeholder="nom du patient"></input>
              </div>
              <div className="input-ord">
                <p>Prénom: </p>
                <input type="text" placeholder="prénom du patient"></input>
              </div>
            </div>
            <div className="right-patient">
              <div className="input-ord">
                <p>Date:</p>
                <input type="text" value={currentDate} readOnly></input>
              </div>
              <div className="input-ord">
                <p>Age: </p>
                <input type="text" placeholder="âge du patient"></input>
              </div>
            </div>
          </div>

          <br></br>

          <div className="medicamment">
            {medications.map((med, index) => (
              <div key={index} className="medication-entry">
                <div className="input-medicamment">
                  <input
                    type="text"
                    placeholder="Nom du médicament"
                    value={med.name}
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value)
                    }
                    className="bold-input"
                  />
                </div>
                <br></br>
                <div className="dosage-qsp">
                  <div className="input-dosage">
                    <input
                      type="text"
                      placeholder="Posologie"
                      value={med.dosage}
                      onChange={(e) =>
                        handleInputChange(index, "dosage", e.target.value)
                      }
                    />
                  </div>
                  <br></br>
                  <div className="input-dosage">
                    <input
                      type="text"
                      placeholder="QSP"
                      value={med.duration}
                      onChange={(e) =>
                        handleInputChange(index, "duration", e.target.value)
                      }
                    />
                  </div>
                </div>
                <br></br>
                <br></br>
              </div>
            ))}
            <button className="btn-ord" onClick={addMedication}>
              Ajouter un médicament
            </button>
          </div>
          <br></br>

          <div className="barcode-container">
            <Barcode value="1234567890" className="barcode" />
          </div>
        </div>
      </div>
      <div className="btns-downsend">
        <button className="btn-down-send" onClick={sendPDF}>
          Envoyer
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default PrescriptionMed;
