import React, { useState } from "react";
import axios from "axios";
import "../styles/componentStyle/MainProfilMedecin.css";
import EditButton from "../components/EditButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../constants";

const MainProfilMedecin = () => {
  const [medecin, setMedecin] = useState(
    JSON.parse(sessionStorage.getItem("medecin"))
  );
  const [isEditingPersonalInfo, setIsEditingPersonalInfo] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [personalInfo, setPersonalInfo] = useState({
    nom: medecin.nom,
    prenom: medecin.prenom,
    specialite: medecin.specialite,
    adresse: medecin.adresse,
    telephone: medecin.telephone,
  });

  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(
    medecin.image
      ? `${API_URL}${medecin.image}`
      : "https://picsum.photos/300/200"
  );

  const handleEditPersonalInfo = () => {
    setIsEditingPersonalInfo(!isEditingPersonalInfo);
  };

  const handleSavePersonalInfo = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/medecin/${medecin.id}`,
        personalInfo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      toast.success("Informations personelles mise a jour avec succès !");
      setMedecin(response.data);
      sessionStorage.setItem("medecin", JSON.stringify(response.data));
      setIsEditingPersonalInfo(false);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des informations personnelles :",
        error
      );
    }
  };

  const handleCancelEdit = () => {
    setIsEditingPersonalInfo(false);
    setPersonalInfo({
      nom: medecin.nom,
      prenom: medecin.prenom,
      specialite: medecin.specialite,
      adresse: medecin.adresse,
      telephone: medecin.telephone,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "currentPassword" ||
      name === "newPassword" ||
      name === "confirmPassword"
    ) {
      setPassword((prevPasswords) => ({
        ...prevPasswords,
        [name]: value,
      }));
    } else {
      setPersonalInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  const handleEditPassword = async () => {
    try {
      if (password.newPassword !== password.confirmPassword) {
        toast.error("Les nouveaux mots de passe ne correspondent pas !");
        return;
      }

      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/medecin/updatepassword/${medecin.id}`,
        {
          currentPassword: password.currentPassword,
          newPassword: password.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      toast.success("Mot de passe mis à jour avec succès !");
      setIsEditingPassword(false);
      setPassword({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour du mot de passe :", error);
      toast.error("Erreur lors de la mise à jour du mot de passe !");
    }
  };
  //Imageeeee
  const handleEditImage = () => {
    setIsEditingImage(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImageURL(URL.createObjectURL(file)); // Mettre à jour l'URL de l'image pour un aperçu
  };

  const handleSaveImage = async () => {
    const formData = new FormData();
    formData.append("image", image);

    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/medecin/${medecin.id}/image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const updatedMedecin = {
        ...medecin,
        image: response.data.image, // Mettre à jour l'image de l'utilisateur
      };
      console.log("image only response data", image);

      toast.success("Photo de profil mise à jour avec succès !");
      setMedecin(updatedMedecin);
      console.log("image et tout", updatedMedecin);

      sessionStorage.setItem("medecin", JSON.stringify(updatedMedecin)); // Mettre à jour sessionStorage
      setIsEditingImage(false);
      setImage(null);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de la photo de profil :",
        error
      );
      toast.error("Erreur lors de la mise à jour de la photo de profil !");
    }
  };

  return (
    <>
      <div className="title-liste-profil">
        <h3>Mon Profil</h3>
        <br></br>
        <hr></hr>
      </div>
      <div className="profile-container">
        <div className="profil-img-name">
          <div className="profile-image-container">
            <img src={imageURL} alt="img" />
          </div>
          <div className="profil-title">
            <h2>Photo de profil</h2>
            <br></br>
            {isEditingImage ? (
              <>
                <input type="file" onChange={handleImageChange} />
                <button onClick={handleSaveImage} className="btn-modan">
                  Sauvegarder la photo
                </button>
              </>
            ) : (
              <>
                <EditButton onClick={handleEditImage} />
              </>
            )}
          </div>
          <br />
        </div>
      </div>
      <br />
      <div className="profile-container">
        <div className="personnal-information">
          <h2>Informations personnelles</h2>
          <br />
          {isEditingPersonalInfo ? (
            <>
              <input
                className="info-pro-item"
                type="text"
                name="nom"
                value={personalInfo.nom}
                onChange={handleInputChange}
                placeholder="Nom"
              />
              <input
                className="info-pro-item"
                type="text"
                name="prenom"
                value={personalInfo.prenom}
                onChange={handleInputChange}
                placeholder="Prénom"
              />
              <input
                className="info-pro-item"
                type="text"
                name="specialite"
                value={personalInfo.specialite}
                onChange={handleInputChange}
                placeholder="Spécialité"
              />
              <input
                className="info-pro-item"
                type="text"
                name="adresse"
                value={personalInfo.adresse}
                onChange={handleInputChange}
                placeholder="Adresse"
              />
              <input
                className="info-pro-item"
                type="text"
                name="telephone"
                value={personalInfo.telephone}
                onChange={handleInputChange}
                placeholder="Numéro de téléphone"
              />
              <button onClick={handleSavePersonalInfo} className="btn-modan">
                Sauvegarder
              </button>
              <button onClick={handleCancelEdit} className="btn-modan">
                Annuler
              </button>
            </>
          ) : (
            <>
              <p className="info-pro-item">Nom : {medecin.nom}</p>
              <p className="info-pro-item">Prénom : {medecin.prenom}</p>
              <p className="info-pro-item">Spécialité : {medecin.specialite}</p>
              <p className="info-pro-item">Adresse : {medecin.adresse}</p>
              <p className="info-pro-item">
                Numéro de téléphone : {medecin.telephone}
              </p>
              <EditButton onClick={handleEditPersonalInfo} />
            </>
          )}
        </div>
      </div>
      <br />
      <div className="profile-container">
        <h2>Changer le mot de passe</h2>
        <br />
        {isEditingPassword ? (
          <>
            <input
              className="input-profil"
              type="password"
              name="currentPassword"
              value={password.currentPassword}
              onChange={handleInputChange}
              placeholder="Saisir votre mot de passe actuel"
            />
            <input
              className="input-profil"
              type="password"
              name="newPassword"
              value={password.newPassword}
              onChange={handleInputChange}
              placeholder="Saisir votre nouveau mot de passe"
            />
            <input
              className="input-profil"
              type="password"
              name="confirmPassword"
              value={password.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirmer votre nouveau mot de passe"
            />
            <button onClick={handleEditPassword} className="btn-modan">
              Sauvegarder le mot de passe
            </button>
            <button
              onClick={() => setIsEditingPassword(false)}
              className="btn-modan"
            >
              Annuler
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsEditingPassword(true)}
            className="btn-modan"
          >
            Modifier le mot de passe
          </button>
        )}
      </div>
      <br></br>

      <br />
      <ToastContainer />
    </>
  );
};

export default MainProfilMedecin;
