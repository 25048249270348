import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/componentStyle/SearchBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

const SearchBar = (props) => {
  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryParam = queryParams.get("query");
  const filterParam = queryParams.get("filter");

  const [isListVisible, setIsListVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(filterParam || "Tout");
  const [query, setQuery] = useState(queryParam || "");
  const navigate = useNavigate();

  const toggleListVisibility = (item) => {
    setSelectedItem(item);
    setIsListVisible(!isListVisible);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    navigate(`/findyourdoctor?query=${query}&filter=${selectedItem}`);
  };

  const customClass = props.customClass ? props.customClass : "search-input";
  return (
    <div>
      <form onSubmit={handleSearch}>
        <div className="search-container">
          <div className="dropdown">
            <div
              id="drop-text"
              onClick={() => toggleListVisibility(selectedItem)}
              className="dropdown-text"
            >
              <span className="tout">{selectedItem}</span>
              <FontAwesomeIcon id="icon" icon={faChevronDown} />
            </div>
            <ul
              id="list"
              className={isListVisible ? "dropdown-list show" : "dropdown-list"}
            >
              <li
                className="dropdown-list-item"
                onClick={() => toggleListVisibility("Tout")}
              >
                Tout
              </li>
              <li
                className="dropdown-list-item"
                onClick={() => toggleListVisibility("Nom du médecin")}
              >
                Nom du médecin
              </li>
              <li
                className="dropdown-list-item"
                onClick={() => toggleListVisibility("Specialité")}
              >
                Specialité
              </li>
              <li
                className="dropdown-list-item"
                onClick={() => toggleListVisibility("Localisation")}
              >
                Localisation
              </li>
            </ul>
          </div>

          <input
            className={customClass}
            type={props.type}
            placeholder={`Rechercher par ${selectedItem}`}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="search-box">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
