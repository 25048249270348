import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"; // Correct import for Button
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Correct import for CloudUpload
import DescriptionIcon from "@mui/icons-material/Description"; // Icon for files
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Icon for PDF
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DocPatient.css";
import axios from "axios";
import { API_URL } from "../../constants";

const DocPatient = () => {
  const [files, setFiles] = useState([]);
  const token = sessionStorage.getItem("token");
  const patient = JSON.parse(sessionStorage.getItem("patient"));
  const patientId = patient.id;

  const rendezVousId = sessionStorage.getItem("currentRendezVousId");

  const handleFileChange = (e) => {
    setFiles((prevFiles) => [...prevFiles, ...Array.from(e.target.files)]);
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append("files", file);
      });
      formData.append("patientId", patientId);
      formData.append("rendezVousId", rendezVousId);

      const response = await axios.post(`${API_URL}/patient/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token} `,
        },
      });
      console.log(response.data);
      toast.success("Fichiers envoyé avec succès !");
      setFiles([]);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const getFileIcon = (fileName) => {
    if (!fileName) return <InsertDriveFileIcon />;
    const extension = fileName.split(".").pop();
    switch (extension) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "jpg":
      case "jpeg":
      case "png":
        return <DescriptionIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  return (
    <div>
      <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
        Documents à partager avec le médecin :
      </Typography>
      <div
        className="shareDoc-container"
        sx={{
          border: "1px solid gray",
          display: "flex",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <input
          multiple
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            color="primary"
            className="cloud-button"
          >
            <CloudUploadIcon className="onlyMe" />
            Déposer vos documents ici
          </Button>
        </label>
        {files.length > 0 && (
          <div className="file-preview">
            {files.map((file) => (
              <div
                key={file.name}
                style={{ display: "flex", alignItems: "center" }}
              >
                {getFileIcon(file.name)}
                <span
                  style={{
                    color: "black",
                    marginLeft: "8px",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: "19px",
                    marginTop: "10px",
                  }}
                >
                  {file.name}
                </span>
              </div>
            ))}
          </div>
        )}
        <Button
          variant="contained"
          onClick={handleFileUpload}
          disabled={files.length === 0}
          sx={{ marginTop: "16px", backgroundColor: "#024631" }}
        >
          Envoyer
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DocPatient;
