import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import "../styles/componentStyle/ObservationMedView.css";

const ObservationMedView = ({ rdv }) => {
  const [note, setNote] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log("note khaala", note);
  useEffect(() => {
    const fetchNote = async () => {
      const token = sessionStorage.getItem("token");
      try {
        const response = await axios.get(
          `${API_URL}/medecin/noterendezvous/${rdv.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setNote(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching note:", error);
        setNote(null);
        setLoading(false);
      }
    };

    fetchNote();
  }, [rdv.id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!note) {
    return <p>Aucune note trouvée pour ce rendez-vous.</p>;
  }

  return (
    <div>
      <h4 className="observation-title">Notes cliniques :</h4>
      <div>
        <div className="observation-medical-container">
          <div>
            <p className="observation-item">Motif</p>
            <textarea className="txt-size" value={note.motif} readOnly />
            <p className="observation-item">Interrogatoire</p>
            <textarea
              className="txt-size-interro"
              value={note.interrogatoire}
              readOnly
            />
            <p className="observation-item">Données de suivi</p>
            <div className="obser-inputs">
              <input className="input-size" value={note.age} readOnly />
              <input className="input-size" value={note.poids} readOnly />
              <input className="input-size" value={note.taille} readOnly />
              <input className="input-size" value={note.grpSang} readOnly />
            </div>
            <p className="observation-item">Antécédents personnels</p>
            <textarea
              className="txt-size-ant"
              value={note.antecedentPerso}
              readOnly
            />
            <p className="observation-item">Antécédents familiaux</p>
            <textarea
              className="txt-size-ant"
              value={note.antecedentsFami}
              readOnly
            />
            <p className="observation-item">Conclusion</p>
            <textarea
              className="txt-size-conclusion"
              value={note.conclusion}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObservationMedView;
