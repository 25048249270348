import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../constants";
import dayjs from "dayjs";
import { createStyles, makeStyles } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "20px",
      marginLeft: "20px",
      marginTop: "50px",
      width: "310px",
    },
  });
});

const SearchHistorique = ({ setRendezVous }) => {
  const { id: patientId } = useParams();
  const [filters, setFilters] = useState({
    date: null,
  });

  const handleDateChange = (v) => {
    setFilters({
      ...filters,
      date: v,
    });
  };

  useEffect(() => {
    const fetchRendezVous = async () => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await axios.get(`${API_URL}/medecin/rendezvous/date/${patientId}`, {
          params: {
            date: filters.date.format("YYYY/MM/DD"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data.map((rdv) => ({
          id: rdv.id,
          patient: {
            nom: rdv.patient.nom,
            prenom: rdv.patient.prenom,
          },
          motif: rdv.motif.toLowerCase() === "premierefois" ? "Premiere fois" : "Suivi",
          date: dayjs(rdv.date).format("YYYY/MM/DD"),
          heure: rdv.heure,
          mode: rdv.mode.toLowerCase() === "video" ? "Video" : "Cabinet",
        }));

        setRendezVous(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des rendez-vous", error);
      }
    };

    fetchRendezVous();
  }, [filters]);

  return (
    <div>
      <div id="app">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker sx={{ marginLeft: "20px", marginTop: "10px" }} format="DD/MM/YYYY" value={filters.date} onChange={handleDateChange} />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default SearchHistorique;
