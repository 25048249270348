import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/pageStyle/CreerRendezVous.css";
import logo from "../../assets/images/LogoDark.png";
import Doctor from "../../components/Doctor";
import Stepper from "../../components/Stepper";

const CreerRendezVous = () => {
  const location = useLocation();
  const { doctor } = location.state;
  useEffect(() => {
    document.body.classList.add("rdv");

    return () => {
      document.body.classList.remove("rdv");
    };
  }, []);
  return (
    <div className="rdv-grid">
      <div className="left-grid">
        <img src={logo} alt="logo" className="logo-rdv"></img>
        <p className="text-rdv">Creer votre rendez-vous</p>
        <div className="detail-doctor">
          <Doctor doctor={doctor} />
        </div>
      </div>
      <div className="right-grid">
        <br></br>
        <br></br>
        <br></br>
        <Stepper />
      </div>
    </div>
  );
};

export default CreerRendezVous;
