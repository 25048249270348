import React from "react";
import { Card, CardBody, CardSubtitle, CardText } from "reactstrap";
import "../styles/componentStyle/CardPatient.css";
import { Link } from "react-router-dom";
import { API_URL } from "../constants";

const CardPatient = ({ patient }) => {
  if (!patient) return null;

  const imageURL = patient.image
    ? `${API_URL}${patient.image}`
    : "https://picsum.photos/300/200";

  return (
    <div className="patients-cards">
      <Card
        style={{
          width: "22rem",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <img alt={patient.nom} src={imageURL} className="image-patient" />
        <br />
        <CardBody>
          <CardSubtitle className="patient-fullname">
            {patient.prenom} {patient.nom}
          </CardSubtitle>
          <CardText className="patient-details">
            Adresse: {patient.adresse}
          </CardText>
          <CardText className="patient-details">
            Telephone: {patient.telephone}
          </CardText>
          <div className="btn-historique">
            <Link to={`/medecin/historique/${patient.id}`}>
              <button className="btn-patient">
                Consulter l'historique du patient
              </button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardPatient;
