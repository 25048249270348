const DaysList = [
  {
    day: "Dimanche",
  },
  {
    day: "Lundi",
  },
  {
    day: "Mardi",
  },
  {
    day: "Mercredi",
  },
  {
    day: "Jeudi",
  },
  {
    day: "Vendredi",
  },
  {
    day: "Samedi",
  },
];
export default DaysList;
