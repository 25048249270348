import React, { useEffect } from "react";
import "../../styles/pageStyle/InscriptionPat.css";
import logo from "../../assets/images/Logo.png";
import FormMaker from "../../components/FormMaker.js";
import { toast } from "react-toastify";
import axios from "axios";
import {
  faUser,
  faCalendarAlt,
  faMapMarkerAlt,
  faPhone,
  faKey,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants.js";

const InscriptionPatient = ({ handleLogin }) => {
  const Navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("signup-pat");

    return () => {
      document.body.classList.remove("signup-pat");
    };
  }, []);

  async function handleSubmit(values) {
    console.log("values");
    const {
      nom,
      prenom,
      civilite,
      dateNaissance: { day: jour, month: mois, year: annee },
      email,
      adresse,
      telephone,
      password,
      cpassword,
      image,
    } = values;

    try {
      if (
        !nom ||
        !prenom ||
        !jour ||
        !mois ||
        !annee ||
        !email ||
        !adresse ||
        !telephone ||
        !password ||
        !civilite ||
        !cpassword
      ) {
        throw new Error("Veuillez remplir tous les champs");
      }

      if (password !== cpassword) {
        toast.error("Les mots de passe ne correspondent pas.");
        return;
      }
      const formData = new FormData();
      formData.append("nom", nom);
      formData.append("prenom", prenom);
      formData.append("civilite", civilite);
      formData.append("adresse", adresse);
      formData.append("email", email);
      formData.append("jour", jour);
      formData.append("mois", mois);
      formData.append("annee", annee);
      formData.append("telephone", telephone);
      formData.append("password", password);
      formData.append("image", image);

      const response = await axios.post(
        `${API_URL}/patient/inscription/patient`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const token = response.data.token;
      localStorage.setItem("token", token);
      const userData = response.data;
      sessionStorage.setItem("token", userData.token);
      sessionStorage.setItem("patient", JSON.stringify(userData.patient));

      handleLogin();
      toast.success("Inscription réussie !");
      setTimeout(() => {
        Navigate("/patient");
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error("Une erreur est survenue");
    }
  }

  return (
    <>
      <div className="form-pat">
        <FormMaker
          logo={<img src={logo} alt="logo" className="logo-form" />}
          headline={"Qu'attendez-vous, rejoignez-nous !"}
          onSubmit={handleSubmit}
          inputs={[
            {
              name: "civilite",
              label: "Civilité",
              options: [
                {
                  label: "Madame",
                  value: "madame",
                },
                {
                  label: "Madmoiselle",
                  value: "madmoiselle",
                },
                {
                  label: "Monsieur",
                  value: "monsieur",
                },
              ],
              type: "select",
            },
            [
              {
                icon: <FontAwesomeIcon icon={faUser} />,
                name: "nom",
                label: "Nom",
                type: "text",
              },
              {
                icon: <FontAwesomeIcon icon={faUser} />,
                name: "prenom",
                label: "Prénom",
                type: "text",
              },
            ],
            {
              icon: <FontAwesomeIcon icon={faMailBulk} />,
              name: "email",
              label: "Email",
              type: "text",
            },
            {
              icon: <FontAwesomeIcon icon={faMapMarkerAlt} />,
              name: "adresse",
              label: "Adresse",
              type: "text",
            },
            {
              icon: <FontAwesomeIcon icon={faPhone} />,
              name: "telephone",
              label: "Numéro de téléphone",
              type: "text",
            },
            {
              icon: <FontAwesomeIcon icon={faCalendarAlt} />,
              name: "dateNaissance",
              type: "date",
            },
            {
              icon: <FontAwesomeIcon icon={faKey} />,
              name: "password",
              label: "Mot de passe",
              type: "password",
            },
            {
              icon: <FontAwesomeIcon icon={faKey} />,
              name: "cpassword",
              label: "Confirmer Mot de passe",
              type: "password",
            },
            {
              name: "image",
              type: "file",
            },
            {
              name: "loginLink",
              label: "Vous avez deja un compte ? Connectez-vous",
              link: "/authentification/patient",
              type: "link",
            },
          ]}
        />
      </div>
    </>
  );
};
export default InscriptionPatient;
