import Home from "../pages/Patient/AcceuilPatient";
import HomeMed from "../pages/Medecin/AcceuilMedecin";
import InscriMed from "../pages/Medecin/InscriptionMedecin";
import InscriPat from "../pages/Patient/InscriptionPatient";
import AuthMed from "../pages/Medecin/AuthentificationMedecin";
import AuthPat from "../pages/Patient/AuthentificationPatient";
import MedPortal from "../pages/Medecin/PortailMedecin";
import PatPortal from "../pages/Patient/PortailPatient";
import VisioPage from "../pages/VisioPage";
import Messagerie from "../components/Chat/Messagerie";
import FindYourDoctor from "../pages/Patient/FindYourDoctor";
import MesDisponibilites from "../pages/Medecin/MesDisponibilites";
import CreerRendezVous from "../pages/Patient/CreerRendezVous";
import MesRendezVous from "../pages/Patient/MesRendezVous";
import DossierMedical from "../pages/Medecin/DossierMedical";
import ProfilMed from "../pages/Medecin/ProfilMed";
import ProfilPat from "../pages/Patient/ProfilPat";
import Prescriptions from "../pages/Patient/Prescriptions";
import MesPatients from "../pages/Medecin/MesPatients";
import MesMedecins from "../pages/Patient/MesMedecins";
import Historique from "../pages/Medecin/Historique";

export const publicRoutes = (handleLogin) => [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/accueil/medecin",
    element: <HomeMed />,
  },
  {
    path: "/inscription/medecin",
    element: <InscriMed handleLogin={handleLogin} />,
  },
  {
    path: "/inscription/patient",
    element: <InscriPat handleLogin={handleLogin} />,
  },
  {
    path: "/authentification/medecin",
    element: <AuthMed handleLogin={handleLogin} />,
  },
  {
    path: "/authentification/patient",
    element: <AuthPat handleLogin={handleLogin} />,
  },

  {
    path: "/findyourdoctor",
    element: <FindYourDoctor />,
  },
  {
    path: "/creerunrendezvous/:id",
    element: <CreerRendezVous />,
  },
];

export const privateRoutes = (handleLogout) => [
  {
    path: "/medecin",
    element: <MedPortal handleLogout={handleLogout} />,
  },
  {
    path: "/patient",
    element: <PatPortal handleLogout={handleLogout} />,
  },
  {
    path: "/availabilities",
    element: <MesDisponibilites handleLogout={handleLogout} />,
  },
  {
    path: "/medecin/mespatients",
    element: <MesPatients handleLogout={handleLogout} />,
  },
  {
    path: "/patient/mesmedecins",
    element: <MesMedecins handleLogout={handleLogout} />,
  },
  {
    path: "/patient/mesrendezvous",
    element: <MesRendezVous handleLogout={handleLogout} />,
  },
  {
    path: "/medecin/visioconference/:rendezVousId",
    element: <VisioPage userType={"medecin"} />,
  },
  {
    path: "/patient/visioconference/:rendezVousId",
    element: <VisioPage userType={"patient"} />,
  },
  {
    path: "/medecin/dossiersmedicaux",
    element: <DossierMedical handleLogout={handleLogout} />,
  },
  {
    path: "/patient/prescriptions",
    element: <Prescriptions handleLogout={handleLogout} />,
  },
  {
    path: "/chat",
    element: <Messagerie handleLogout={handleLogout} />,
  },
  {
    path: "/medecin/profil",
    element: <ProfilMed handleLogout={handleLogout} />,
  },
  {
    path: "/patient/profil",
    element: <ProfilPat handleLogout={handleLogout} />,
  },
  {
    path: "/medecin/historique/:id",
    element: <Historique handleLogout={handleLogout} />,
  },
];
