import React from "react";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import "../styles/componentStyle/RDVCard.css";

const RDVCard = (props) => {
  return (
    <div className="rdvCard">
      <div className="top-card">
        <img src={props.image} alt="titre" className="card__image" />
        <div className="name-spec">
          <h2 className="profile-name">{props.title}</h2>
          <p className="spec">{props.subtitle}</p>
        </div>
      </div>

      <div className="adresse-card">
        <LocationOnIcon />
        {props.adresse}
      </div>
    </div>
  );
};

export default RDVCard;
