import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Button from "../components/Button";
import "../styles/componentStyle/ProfileRating.css";
import { API_URL } from "../constants";

function ProfileRating(props) {
  const [averageRating, setAverageRating] = useState(0);
  const theUser = sessionStorage.getItem("userType");
  const medecinId = props.id;
  const location = useLocation();

  const isChat = location.pathname.includes("chat");

  useEffect(() => {
    if (theUser === "medecin" && medecinId) {
      const fetchAverageRating = async () => {
        const token = sessionStorage.getItem("token");
        try {
          const response = await axios.get(
            `${API_URL}/medecin/rating/average/${medecinId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAverageRating(response.data.averageRating);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération de la note moyenne",
            error
          );
        }
      };

      fetchAverageRating();
    }
  }, [medecinId, theUser]);

  const renderStars = () => {
    const maxStars = 5;
    const rating = Math.floor(averageRating);
    return (
      <div className="stars">
        {[...Array(maxStars)].map((_, i) => (
          <span key={i} className={i < rating ? "star filled" : "star"}>
            &#9733;
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="profile-rating">
      <Link>
        <img src={props.profileImage} alt="Doctor's profile" id="profilePic" />
      </Link>
      <h2 className="profile-name">{props.name}</h2>
      <p className="profile-role">{props.role}</p>
      <p className="profile-role">{props.adresse}</p>

      {props.specialite && props.showRating && (
        <div className="fetch-average">{renderStars()}</div>
      )}

      <br></br>
      <br></br>
      <br></br>

      {!isChat && (
        <>
          {theUser === "patient" ? (
            <Button
              btnLink={"/patient/profil"}
              btnText="Modifier le profil"
              customClass={"profile-btn"}
            />
          ) : (
            <Button
              btnLink={"/medecin/profil"}
              btnText="Modifier le profil"
              customClass={"profile-btn"}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ProfileRating;
