import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import "../../styles/pageStyle/portailPatient.css";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { Description as PrescriptionIcon } from "@mui/icons-material";
import { Person as PersonIcon } from "@mui/icons-material";
import { Event as EventIcon } from "@mui/icons-material";
import { Message as MessageChannelIcon } from "@mui/icons-material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import MainMyMedecins from "../../components/MainMyMedecins";
import ProfileRating from "../../components/ProfileRating";
import { API_URL } from "../../constants";

const MesMedecins = ({ handleLogout }) => {
  const patient = JSON.parse(sessionStorage.getItem("patient"));
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    const confirmLogout = window.confirm(
      "Voulez vous vraimant vous déconnecter ?"
    );
    if (confirmLogout) {
      sessionStorage.removeItem("patient");
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      handleLogout();
      navigate("/");
    }
  };
  useEffect(() => {
    document.body.classList.add("portal");

    return () => {
      document.body.classList.remove("portal");
    };
  }, []);

  const listItems = [
    { text: "Recherche de medecin", icon: <DashboardIcon />, link: "/patient" },
    {
      text: "Mes medécins",
      icon: <PersonIcon />,
      link: "/patient/mesmedecins",
    },
    {
      text: "Mes rendez-vous",
      icon: <EventIcon />,
      link: "/patient/mesrendezvous",
    },
    {
      text: "Mes prescriptions",
      icon: <PrescriptionIcon />,
      link: "/patient/prescriptions",
    },
    { text: "Messagerie", icon: <MessageChannelIcon />, link: "/chat" },

    { text: "Paramètres", icon: <SettingsIcon />, link: "/patient/profil" },
    {
      text: "Se déconnecter",
      icon: <ExitToAppIcon />,
      link: "/",
      onClick: handleLogoutClick,
    },
  ];

  const imageURL = patient.image
    ? `${API_URL}${patient.image}`
    : "https://picsum.photos/300/200";

  return (
    <div>
      <div className="set-portal-grid">
        <div>
          <SideBar listItems={listItems} />
        </div>
        <div className="main-dash">
          <MainMyMedecins />
        </div>
        <div>
          {imageURL ? (
            <ProfileRating
              showRating={false}
              profileImage={imageURL}
              name={`${patient.nom} ${patient.prenom}`}
              adresse={`${patient.adresse}`}
            />
          ) : (
            <p>Image not available</p>
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default MesMedecins;
