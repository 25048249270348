import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Service from "../../components/Service";
import priseRDV0 from "../../assets/icons/carto.png";
import priseRDV1 from "../../assets/icons/time.png";
import priseRDV2 from "../../assets/icons/visio.png";
import priseRDV3 from "../../assets/icons/share.png";
import priseRDV4 from "../../assets/icons/dossier.png";
import priseRDV5 from "../../assets/icons/chat.png";
import Do from "../../components/Do";
import Feedback from "../../components/Feedback";
import "../../styles/pageStyle/AcceuilPatient.css";
import Demo from "../../assets/videos/Demo.mp4";
import Home from "../../assets/images/about15.png";
import SearchBar from "../../components/SearchBar";
import About from "../../assets/images/home15.png";
import { useInView } from "react-intersection-observer";
import Footer from "../../components/Footer";

const AcceuilPatient = () => {
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const temoignageRef = useRef(null);

  const [RefAbout, aboutInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [RefService, serviceInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const handleAboutClick = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleServiceClick = () => {
    serviceRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleTestimonialClick = () => {
    temoignageRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Navbar
        link1Btn={"/accueil/medecin"}
        link2Btn={"/authentification/patient"}
        navBtn={"Vous êtes médecin ?"}
        navBtn2={"Inscription / Connexion"}
        showNav={true}
        showNavBtn2={false}
        showDropBtn={true}
        handleAboutClick={handleAboutClick}
        handleServiceClick={handleServiceClick}
        handleTestimonialClick={handleTestimonialClick}
      />
      <section className="main-section">
        <div className="home-left">
          <h1 className="home-txt">
            Consultez un médecin en ligne, où que vous soyez, en quelques clics
            seulement !
          </h1>
          <h2 className="home-subtxt">Prenez rendez-vous dès aujourd'hui </h2>
          <SearchBar
            placeholder="Rechercher tout"
            customClass="home-searchbar"
          />
        </div>
        <div className="home-right">
          <img src={Home} alt="background" className="home-img"></img>
        </div>
      </section>

      <h1 className="section-title">À propos</h1>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <section className="about-section" ref={RefAbout}>
        <div className={`about-img ${aboutInView ? "active" : ""}`}>
          <img src={About} alt="about" className="about-img"></img>
        </div>
        <div className={`about-content ${aboutInView ? "active" : ""}`}>
          <p className="about-txt">Chez Careline,</p>
          <p className="about-subtxt">
            Nous sommes dévoués à votre bien-être et à votre confort. <br></br>
            Notre plateforme de téléconsultation est là pour répondre à vos
            besoins de santé.
          </p>
          <Do title="Accès facile et rapide à des professionnels de la santé " />
          <Do title="Sans vous deplacer, a portée de clic" />
          <Do title="Disponible 7jrs/7,  24h/24" />
        </div>
      </section>

      <section className="feedback-section" ref={temoignageRef}>
        <Feedback title={"Démonstration"} feedbackVideo={Demo} />
      </section>
      <h1 className="section-title">Nos services</h1>

      <section className="service-section " ref={RefService}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <p className={`service-txt ${serviceInView ? "active" : ""}`}>
          Careline, votre stéthoscope virtuel pour des consultations vous offres
          :
        </p>
        <div className="white">
          <Service icon={priseRDV0} service="Recherche de médecin" />
          <Service icon={priseRDV1} service="Prise de rendez-vous" />
          <div className="visioconf-resize">
            <Service icon={priseRDV2} service="Consultation en visio" />
          </div>
          <Service icon={priseRDV3} service="Partage de documents " />
          <Service icon={priseRDV4} service="Accès prescriptions" />
          <Service icon={priseRDV5} service="Chat en temps réel" />
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default AcceuilPatient;
