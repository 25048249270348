import React from "react";
import dayjs from "dayjs";
import "../../styles/componentStyle/Identification.css";

const Identification = ({ form }) => {
  const fields = {
    motif: "Motif de consultation",
    mode: "Mode de consultation",
    heure: "Heure de consultation",
    date: "Date de consultation",
  };

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD ");
  };

  const filteredFormKeys = Object.keys(form).filter(
    (key) => key !== "medecinId"
  );

  return (
    <div className="container">
      <h1 className="motif-title">Confirmez vos informations:</h1>

      <div className="confirmation-container">
        {filteredFormKeys.map((key) => (
          <div key={key} className="confirmation-field">
            <strong>{fields[key] || key}:</strong>{" "}
            {key === "date" ? formatDate(form[key]) : form[key]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Identification;
