import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import "./Table.css";
import { API_URL } from "../../constants";
import { useNavigate } from "react-router-dom";
import { Button, Input, MenuItem } from "@mui/material";
import Select from "react-select";

export default function BasicTable() {
  const [rows, setRows] = useState([]);
  const Navigate = useNavigate();
  const [filters, setFilters] = useState({
    fullname: "",
    mode: null,
    motif: null,
    date: dayjs().format("YYYY/MM/DD"),
  });

  useEffect(() => {
    const fetchRendezVous = async () => {
      const token = sessionStorage.getItem("token");
      const patient = JSON.parse(sessionStorage.getItem("patient"));
      const patientId = patient.id;

      try {
        const response = await axios.get(
          `${API_URL}/patient/rendezvous/${patientId}`,
          {
            params: {
              ...filters,
              fullname: filters.fullname || null,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("les infos recuperer sont : ", response);

        const data = response.data.map((rdv) => ({
          id: rdv.id,
          medecin: {
            nom: rdv.medecin.nom,
            prenom: rdv.medecin.prenom,
            image: rdv.medecin.image
              ? `${API_URL}${rdv.medecin.image}`
              : "https://picsum.photos/300/200",
          },
          motif:
            rdv.motif.toLowerCase() === "premierefois"
              ? "Premiere fois"
              : "Suivi",
          date: dayjs(rdv.date).format("YYYY/MM/DD"),
          heure: rdv.heure,
          mode: rdv.mode.toLowerCase() === "video" ? "Video" : "Cabinet",
        }));

        setRows(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des rendez-vous", error);
      }
    };

    fetchRendezVous();
  }, [filters]);

  const handleJoinVisio = async (rendezVousId) => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/patient/visiocall/${rendezVousId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const visioData = response.data;

      sessionStorage.setItem("currentRendezVousId", visioData.rendezVousId);
      Navigate(`/patient/visioconference/${rendezVousId}`);
    } catch (error) {
      console.error(
        "Erreur lors de la tentative de rejoindre la visioconférence",
        error
      );
    }
  };

  return (
    <div className="Table">
      <div className="filters">
        <Input
          placeholder="Nom ou prénom du medecin"
          value={filters.fullname}
          onChange={(e) => setFilters({ ...filters, fullname: e.target.value })}
        />
        {(() => {
          const options = [
            {
              label: "Tous les motifs",
              value: null,
            },
            {
              label: "Premiere fois",
              value: "premierefois",
            },
            {
              label: "Suivi",
              value: "suivi",
            },
          ];

          return (
            <Select
              placeholder={"Motif"}
              onChange={(row) => setFilters({ ...filters, motif: row.value })}
              value={options.find((o) => o.value === filters.motif)}
              options={options}
            />
          );
        })()}

        {(() => {
          const options = [
            {
              label: "Tous les modes",
              value: null,
            },
            {
              label: "Video",
              value: "video",
            },
            {
              label: "Cabinet",
              value: "cabinet",
            },
          ];

          return (
            <Select
              placeholder={"Mode"}
              onChange={(row) => setFilters({ ...filters, mode: row.value })}
              value={options.find((o) => o.value === filters.mode)}
              options={options}
            />
          );
        })()}

        {(() => {
          const options = [
            {
              label: "Aujourd'hui",
              value: dayjs().format("YYYY/MM/DD"),
            },
            {
              label: "Toutes les dates",
              value: null,
            },
          ];

          return (
            <Select
              placeholder={"Date"}
              onChange={(row) => setFilters({ ...filters, date: row.value })}
              value={options.find((o) => o.value === filters.date)}
              options={options}
            />
          );
        })()}
      </div>
      <h2>Liste des rendez-vous ({rows.length})</h2>
      <br />
      <TableContainer
        style={{
          boxShadow: "0px 13px 20px 0px #80808029",
          maxHeight: "500px",
          overflowY: "auto",
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom du medecin</TableCell>
              <TableCell align="left">Motif</TableCell>
              <TableCell align="left">Date&nbsp;</TableCell>
              <TableCell align="left">Heure&nbsp;</TableCell>
              <TableCell align="left">Mode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <div className="list-rdv-medecins">
                    <img
                      src={row.medecin.image}
                      alt="k"
                      style={{ width: 50, height: 50, borderRadius: "50%" }}
                    />
                    <h3 className="list-rdv-nom">
                      {" "}
                      {`${row.medecin.nom} ${row.medecin.prenom}`}
                    </h3>
                  </div>
                </TableCell>

                <TableCell align="left">{row.motif}</TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.heure}</TableCell>
                <TableCell align="left">{row.mode}</TableCell>
                <TableCell align="left">
                  {(() => {
                    const rendezvousReady =
                      dayjs().format("YYYY/MM/DD") === row.date;

                    return (
                      <>
                        {rendezvousReady ? (
                          <button
                            className="btn-rej"
                            onClick={() => handleJoinVisio(row.id)}
                          >
                            Rejoindre
                          </button>
                        ) : (
                          <Button disabled={true} className="btn-rej">
                            Rejoindre
                          </Button>
                        )}
                      </>
                    );
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
