import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import axios from "axios";
import { API_URL } from "../../constants";
import "./ListDoc.css";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const ScrollableList = styled(List)(({ theme }) => ({
  maxHeight: "600px", // Adjust height based on your needs
  overflowY: "auto",
}));

const retrieveDoc = async () => {
  const token = sessionStorage.getItem("token");
  const rendezVousId = sessionStorage.getItem("currentRendezVousId");
  try {
    const response = await axios.get(
      `${API_URL}/medecin/files/${rendezVousId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.file;
  } catch (error) {
    console.error("Error retrieving files:", error);
    return { filename: [], url: [] };
  }
};

export default function InteractiveList() {
  const [files, setFiles] = useState({ filename: [], url: [] });

  useEffect(() => {
    const fetchFiles = async () => {
      const retrievedFiles = await retrieveDoc();
      setFiles(retrievedFiles);
    };
    fetchFiles();

    const interval = setInterval(() => {
      fetchFiles();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          {files.filename.length === 0 ? (
            <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
              Aucun document envoyé encore
            </Typography>
          ) : (
            <Demo>
              <ScrollableList>
                <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
                  Documents médicaux partagés :
                </Typography>
                <List>
                  {files.filename.map((filename, index) => (
                    <ListItem
                      key={index}
                      sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="download"
                          href={files.url[index]}
                          target="_blank"
                          download
                        ></IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <FolderIcon style={{ color: "green" }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={filename}
                        secondary={files.filename[index]}
                        onClick={() =>
                          window.open(`${API_URL}${files.url[index]}`, "_blank")
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </ScrollableList>
            </Demo>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
