import React, { useState, useEffect } from "react";
import { API_URL } from "../../constants";
import "./Conversations.css";
import axios from "axios";

const Conversations = ({ onSelectUser }) => {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const fetchConversations = async () => {
      const token = sessionStorage.getItem("token");
      try {
        const response = await axios.get(`${API_URL}/users/conversations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setConversations(response.data.conversations);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, []);

  return (
    <ul className="conversations-list">
      {conversations.map((conversation) => (
        <li key={conversation.id} onClick={() => onSelectUser(conversation.user)}>
          <div className="searchUser">
            <img
              className="user-img-chat"
              src={
                conversation.image
                  ? `${API_URL}${conversation.image}`
                  : "https://picsum.photos/300/200"
              }
              alt="img"
            />
            <div className="content-p">
              <div className="userChatInfo">
                <span>{conversation.nom}</span>
                <span>{conversation.prenom}</span>
              </div>

              <br></br>

              <div>
                <p>{conversation.content}</p>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Conversations;
