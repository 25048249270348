import React from "react";
import "../../components/ModeConsultation/ModeConsultation.css";
import LocationOption from "../Data/LocationOption";

const ModeConsultation = ({ mode, handleChange }) => {
  return (
    <div className="mr">
      <p className="sidebar-title">Choisissez votre mode de consultation</p>
      <div className="icons-fetch">
        {LocationOption.map((option, index) => (
          <div
            key={index}
            className="mode-icon"
            onClick={() => handleChange(option.name)}
            style={{
              ...(option.name === mode ? { background: "#93b838" } : {}),
            }}
          >
            <img src={option.icon} width={80} height={70} alt={option.name} />
            <h2 className="icon-title">{option.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModeConsultation;
