import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../components/MenuVisioMedecin/ListDoc.css";

const BilanPatient = () => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const retrieveBilan = async () => {
    const token = sessionStorage.getItem("token");
    const rendezVousId = sessionStorage.getItem("currentRendezVousId");

    try {
      const response = await axios.get(
        `${API_URL}/patient/bilan/${rendezVousId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPdfUrl(`${API_URL}${response.data.file}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      retrieveBilan();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            {pdfUrl ? (
              <iframe
                src={pdfUrl}
                title="Ordonnance"
                width="100%"
                height="600px"
              />
            ) : (
              <p>Chargement du bilan...</p>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BilanPatient;
