import React from "react";
import Calendar from "./Calendar";
import axios from "axios";
import "../../styles/componentStyle/MeetingSchedule.css";
import { useParams } from "react-router-dom";
import { AvailableTimeSlots, DAYS } from "../../utils/day";
import { API_URL } from "../../constants";
import dayjs from "dayjs";

const MeetingSchedule = ({ form, setForm }) => {
  const { id: medecinId } = useParams();
  const token = localStorage.getItem("token");
  const [disponibilite, setDisponibilite] = React.useState([]);
  const [rendezvous, setRendezvous] = React.useState([]);
  const [selectedTime, setSelectedTime] = React.useState("");

  React.useEffect(() => {
    axios
      .get(`${API_URL}/patient/disponibilite/${medecinId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDisponibilite(response.data.disponibilite);
        setRendezvous(response.data.rendezvous);
      })
      .catch((err) => {});
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const availableTimeSlots = () => {
    const timeslots = [];
    const rendezvousAujourdhui = rendezvous.filter(
      (r) => r.date === form.date.format("YYYY/MM/DD")
    );

    const currentTime = getCurrentTime();

    disponibilite.forEach((d) => {
      const dayName = DAYS.find((d) => d.value === form.date.get("day")).name;
      if (d.jour === dayName) {
        const hd = parseInt(d.heureDebut.split(":")[0]);
        const hf = parseInt(d.heureFin.split(":")[0]);

        for (let i = hd; i < hf; i++) {
          const one = `${String(i).padStart(2, "0")}:00`;

          const two = `${String(i).padStart(2, "0")}:30`;

          const oneExists = rendezvousAujourdhui.find((r) => r.heure === one);
          const twoExists = rendezvousAujourdhui.find((r) => r.heure === two);

          if (
            !oneExists &&
            (one > currentTime ||
              form.date.format("YYYY/MM/DD") !== dayjs().format("YYYY/MM/DD"))
          ) {
            timeslots.push(one);
          }
          if (
            !twoExists &&
            (two > currentTime ||
              form.date.format("YYYY/MM/DD") !== dayjs().format("YYYY/MM/DD"))
          ) {
            timeslots.push(two);
          }
        }
      }
    });
    return timeslots;
  };

  const handleTimeSlotClick = (time) => {
    setSelectedTime(time);
    setForm({ ...form, heure: time });
  };

  return (
    <div className="meeting-schedule">
      <h1 className="motif-title">Creneau horaire</h1>
      <p className="sidebar-title"> Choisissez un creneau de consultation</p>
      <div className="time-date-patient">
        <div>
          <Calendar
            form={form}
            setForm={setForm}
            disponibilite={disponibilite}
            rendezvous={rendezvous}
          />
        </div>
        <div className="grid" style={{ maxHeight: "200px" }}>
          {availableTimeSlots().map((time, index) => (
            <AvailableTimeSlots
              key={index}
              time={time}
              onClick={handleTimeSlotClick}
              isActive={selectedTime === time}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MeetingSchedule;
