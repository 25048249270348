export const API_URL = "https://api.careline.litissiahadjout.com";
// export const API_URL = "http://localhost:4000";

export const MOIS = [
  {
    label: "Janvier",
    value: 1,
  },
  {
    label: "Février",
    value: 2,
  },
  {
    label: "Mars",
    value: 3,
  },
  {
    label: "Avril",
    value: 4,
  },
  {
    label: "Mai",
    value: 5,
  },
  {
    label: "Juin",
    value: 6,
  },
  { label: "Juillet", value: 7 },
  { label: "Août", value: 8 },
  { label: "Septembre", value: 9 },
  { label: "Octobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Décembre", value: 12 },
];

export const ANNEES = Array.from({ length: 2024 - 1940 + 1 }, (v, k) => ({
  label: (1950 + k).toString(),
  value: 1950 + k,
}));

export const JOUR = Array.from({ length: 31 }, (v, k) => ({
  label: (k + 1).toString(),
  value: k + 1,
}));
