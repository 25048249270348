import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyle/SideBar.css";
import LogoDark from "../assets/images/LogoDark.png";

const SideBar = ({ listItems, onClick }) => {
  const [selected, setSelected] = useState(0);
  return (
    <div className="sidebar">
      <img src={LogoDark} alt="logo" className="logo-portal" />
      <div className="menu">
        <ul>
          {listItems.map((item, index) => (
            <div
              className={selected === index ? "menuItem active" : "menuItem"}
              onClick={() => {
                setSelected(index);
              }}
            >
              <li key={index} className="item">
                <Link
                  className="menuItemLink"
                  to={item.link}
                  onClick={item.onClick || onClick}
                >
                  <span className="icon-sidebar">{item.icon}</span>
                  <span className="text">{item.text}</span>
                </Link>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
