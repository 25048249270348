import React from "react";
import "../styles/componentStyle/MainDash.css";
import TableMed from "./Table/TableMed.js";

const MainDashMedecin = () => {
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div className="MainDash">
      <h1 className="txt-portal">
        Bienvenue ! Dr.
        {`${capitalizeFirstLetter(medecin.nom)} ${capitalizeFirstLetter(
          medecin.prenom
        )}`}{" "}
      </h1>
      <br></br>
      <br></br>
      <TableMed />
    </div>
  );
};

export default MainDashMedecin;
