import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../assets/images/LogoDark.png";
import { API_URL } from "../../constants";

const Navbar = ({ userType }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem(userType));
    if (storedUser) {
      setUser(storedUser);
    }
  }, [userType]);

  return (
    <div className="chat-navbar">
      <img src={logo} alt="logo" className="logo-chat" />
      {user && (
        <div className="user">
          <img
            src={
              user.image
                ? `${API_URL}${user.image}`
                : "https://picsum.photos/300/200"
            }
            alt=""
            className="user-img"
          />
          <div className="navbar-chat-nom">
            <span className="deta">{user.nom}</span>
            <span className="deta">{user.prenom}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
