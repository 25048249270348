import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Chat from "./Chat";
import ProfileRating from "../ProfileRating";
import NoChat from "./NoChat";
import "./Messagerie.css";
import { API_URL } from "../../constants";

const Messagerie = () => {
  const userType = sessionStorage.getItem("userType");
  const [selectedUser, setSelectedUser] = useState(null);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const imageURL = selectedUser?.image
    ? `${API_URL}${selectedUser.image}`
    : "https://picsum.photos/300/200";

  return (
    <div className="messagerie">
      <div className="chat-container">
        <Sidebar userType={userType} onSelectUser={handleSelectUser} />
        {selectedUser ? (
          <>
            <Chat userType={userType} user={selectedUser} />
            <div className="height-profil">
              {imageURL ? (
                <ProfileRating
                  specialite={selectedUser.specialite}
                  showRating={false}
                  profileImage={imageURL}
                  name={
                    selectedUser
                      ? `${selectedUser.nom} ${selectedUser.prenom}`
                      : "no chat"
                  }
                  adresse={selectedUser ? `${selectedUser.adresse}` : ""}
                />
              ) : (
                <p>Image not available</p>
              )}
            </div>
          </>
        ) : (
          <NoChat />
        )}
      </div>
    </div>
  );
};

export default Messagerie;
