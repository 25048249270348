import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "../styles/componentStyle/Feedback.css";

function Feedback(props) {
  const [temoignageRef, temoignageInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="feedback-container" ref={temoignageRef}>
      <div className="top">
        <h1 className="section-title">{props.title}</h1>
      </div>
      <Link>
        <video
          src={props.feedbackVideo}
          className={`video-feed ${temoignageInView ? "active" : ""}`}
          controls
          loop
          muted
        ></video>
      </Link>
    </div>
  );
}

export default Feedback;
