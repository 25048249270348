import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchPatient from "./SearchPatient";
import TablePatient from "./Table/TablePatient";
import "../styles/componentStyle/MainMyPatients.css";
import { API_URL } from "../constants";

const MainMyPatients = () => {
  const [patients, setPatients] = useState([]);
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));

  useEffect(() => {
    const RetrieveAllPatients = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(`${API_URL}/medecin/mypatients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPatients(response.data);
      } catch (error) {
        console.error("Erreur lors de la recuperation des patiens: ", error);
      }
    };
    RetrieveAllPatients();
  }, []);

  const handleSearchResults = (results) => {
    setPatients(results);
  };

  return (
    <div>
      <div className="title-liste-pat">
        <p>{`Bonjour Dr ${medecin.nom}, voici la liste de vos patients : `}</p>
      </div>
      <div className="search-pat">
        <SearchPatient onSearchResults={handleSearchResults} />
      </div>
      <div className="table-patients">
        <TablePatient patients={patients} />
      </div>
    </div>
  );
};

export default MainMyPatients;
