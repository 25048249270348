import React from "react";
import "../../components/Motif/motif.css";

const Motif = ({ motif, handleChange }) => {
  return (
    <div>
      <p className="sidebar-title"> Indiquer votre motif de consultation</p>
      <div>
        <label className="sidebar-label-container">
          <input type="radio" name="test" checked={motif === "premierefois"} onClick={() => handleChange("premierefois")} />
          <span className="checkmark"></span>Consultation de première fois
        </label>
        <label className="sidebar-label-container">
          <input type="radio" name="test" checked={motif === "suivi"} onClick={() => handleChange("suivi")}/>
          <span className="checkmark"></span>Consultation de suivi
        </label>
      </div>
    </div>
  );
};

export default Motif;
