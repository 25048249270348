import Button from "../components/Button";

export const DAYS = [
  {
    name: "Dimanche",
    value: 0,
  },
  {
    name: "Lundi",
    value: 1,
  },
  {
    name: "Mardi",
    value: 2,
  },
  {
    name: "Mercredi",
    value: 3,
  },
  {
    name: "Jeudi",
    value: 4,
  },
  {
    name: "Vendredi",
    value: 5,
  },
  {
    name: "Samedi",
    value: 6,
  },
];

export function shouldDisableDate(day, disponibilities, rendezvous) {
  const dayName = DAYS.find((d) => d.value === day.get("day")).name;

  let disableDate = true;

  disponibilities.forEach((d) => {
    if (d.jour === dayName) disableDate = false;
  });

  return disableDate;
}

export function AvailableTimeSlots({ time, onClick, isActive }) {
  const buttonClass = isActive ? "date-patient active" : "date-patient";

  return (
    <Button
      variant="outline"
      btnText={time}
      onClick={() => onClick(time.split(" ")[0])}
      type="button"
      customClass={buttonClass}
    >
      {time}
    </Button>
  );
}

export function isBetween(heureDemande, heureDebut, heureFin) {
  let isBetween = true;

  const [hddHeure] = splitHeureMinute(heureDemande);
  const [hdHeure] = splitHeureMinute(heureDebut);
  const [hfHeure] = splitHeureMinute(heureFin);

  if (hddHeure < hdHeure) isBetween = false;
  if (hddHeure > hfHeure) isBetween = false;

  return isBetween;
}

function splitHeureMinute(value) {
  return value.split(":");
}
