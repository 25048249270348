import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchDoctors from "./SearchDoctors";
import TableDoctors from "./Table/TableDoctors";
import "../styles/componentStyle/MainMyPatients.css";
import { API_URL } from "../constants";

const MainMyMedecins = () => {
  const [medecins, setMedecins] = useState([]);
  const patient = JSON.parse(sessionStorage.getItem("patient"));

  useEffect(() => {
    const RetrieveAllDoctors = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(`${API_URL}/patient/mydoctors`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMedecins(response.data);
      } catch (error) {
        console.error("Erreur lors de la recuperation des patiens: ", error);
      }
    };
    RetrieveAllDoctors();
  }, []);

  const handleSearchResults = (results) => {
    setMedecins(results);
  };

  return (
    <div>
      <div className="title-liste-pat">
        <p>{` Bonjour ${patient.civilite} ${patient.nom}, voici la iste de vos médecins traitans : `}</p>
      </div>
      <div className="search-pat">
        <SearchDoctors onSearchResults={handleSearchResults} />
      </div>
      <div className="table-patients">
        <TableDoctors medecins={medecins} />
      </div>
    </div>
  );
};

export default MainMyMedecins;
