import React from "react";
import { Link } from "react-router-dom";
import DoMark from "../assets/icons/do.png";
import "../styles/componentStyle/Do.css";

function Do(props) {
  return (
    <div className="do-item">
      <Link>
        <img src={DoMark} className="do-icon"></img>
      </Link>
      <p className="do-title">{props.title}</p>
    </div>
  );
}

export default Do;
