import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Logo from "../assets/images/Logo.png";
import "../styles/componentStyle/Navbar.css";

function Navbar({
  navBtn,
  navBtn2,
  link1Btn,
  link2Btn,
  showNavBtn2,
  showDropBtn,
  showNav,
  handleAboutClick,
  handleServiceClick,
  handleTestimonialClick,
}) {
  const [activeLink, setActiveLink] = useState(null);
  const [isDropVisible, setDropIsVisible] = useState(false);

  const toggleDropdown = () => {
    setDropIsVisible(!isDropVisible);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <div className="navbar-container">
      <Link to="/">
        <img src={Logo} alt="logo" id="navbar__logo" />
      </Link>
      {showNav && (
        <nav className="nav-item">
          <ul>
            <li>
              <Link
                className={activeLink === 1 ? "active-link" : "link"}
                onClick={() => {
                  handleLinkClick(1);
                  handleAboutClick();
                }}
              >
                {" "}
                À propos
              </Link>
            </li>
            <li>
              <Link
                className={activeLink === 2 ? "active-link" : "link"}
                onClick={() => {
                  handleLinkClick(2);
                  handleServiceClick();
                }}
              >
                Nos services
              </Link>
            </li>
            <li>
              <Link
                className={activeLink === 3 ? "active-link" : "link"}
                onClick={() => {
                  handleLinkClick(3);
                  handleTestimonialClick();
                }}
              >
                Démonstration
              </Link>
            </li>
          </ul>
        </nav>
      )}
      <div className="button-item">
        <Button
          btnText={navBtn}
          btnLink={link1Btn}
          customClass={"medecin-btn"}
        />
        {showNavBtn2 && (
          <Button
            btnText={navBtn2}
            btnLink={link2Btn}
            customClass={"patient-btn"}
          />
        )}
        {showDropBtn && (
          <>
            <Button
              btnText={navBtn2}
              customClass={"patient-link-btn"}
              onClick={toggleDropdown}
              type={"button"}
            />
            {isDropVisible && (
              <div>
                <ul
                  id="dropdownButton"
                  className={
                    isDropVisible ? "dropdownButton " : "dropdownButton show "
                  }
                >
                  <li className="dropdown-list-item">
                    <Link to="/inscription/patient" className="drop-link">
                      Inscription
                    </Link>
                  </li>
                  <li className="dropdown-list-item">
                    <Link to="/authentification/patient" className="drop-link">
                      Connexion
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;
