import React from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyle/Service.css";

function Service(props) {
  return (
    <div className="service">
      <Link>
        <img src={props.icon} alt="icon-rdv" className="icon-service"></img>
      </Link>
      <p className="text-service">{props.service}</p>
    </div>
  );
}

export default Service;
