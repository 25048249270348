import React, { useRef } from "react";
import Navbar from "../../components/Navbar";
import Button from "../../components/Button";
import Home from "../../assets/images/dct.png";
import "../../styles/pageStyle/AcceuilMedecin.css";
import Do from "../../components/Do";
import About from "../../assets/images/aby.png";
import Feedback from "../../components/Feedback";
import Demo from "../../assets/videos/Demo.mp4";
import priseRDV1 from "../../assets/icons/time.png";
import priseRDV2 from "../../assets/icons/visio.png";
import priseRDV8 from "../../assets/icons/ordbil.png";
import priseRDV5 from "../../assets/icons/chat.png";
import priseRDV4 from "../../assets/icons/dossier.png";
import Service from "../../components/Service";
import Footer from "../../components/Footer";
import { useInView } from "react-intersection-observer";

const AcceuilMedecin = () => {
  //scroll
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const temoignageRef = useRef(null);

  // Affichage in view
  const [RefAbout, aboutInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [RefService, serviceInView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Change this value as per your requirement
  });

  //Scroll
  const handleAboutClick = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleServiceClick = () => {
    serviceRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleTestimonialClick = () => {
    temoignageRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Navbar
        link1Btn={"/inscription/medecin"}
        link2Btn={"/authentification/medecin"}
        showNav={true}
        showNavBtn2={true}
        navBtn={"S'inscrire"}
        navBtn2={"Se connecter"}
        handleAboutClick={handleAboutClick}
        handleServiceClick={handleServiceClick}
        handleTestimonialClick={handleTestimonialClick}
      />
      <section className="main-section">
        <div className="home-left">
          <h1 className="home-txt-med">
            Bienvenue Docteur chez <span className="care">Care</span>
            <span className="line">line</span>
          </h1>
          <h2 className="home-subtxt-med">
            Rejoignez-nous dès aujourd'hui et découvrez comment notre
            application peut transformer votre pratique médicale et vous aider à
            atteindre de nouveaux sommets de succès professionnel !{" "}
          </h2>
          <Button
            btnText={"Rejoindre"}
            customClass={"btn-med"}
            btnLink={"/inscription/medecin"}
          />
        </div>
        <div className="home-right">
          <img src={Home} alt="background" className="home-img-med"></img>
        </div>
      </section>
      <br></br>
      <br></br>

      <h1 className="section-title-med">À propos</h1>
      <section className="about-section" ref={RefAbout}>
        <div>
          <img
            src={About}
            alt="about"
            className={`about-img-med ${aboutInView ? "active" : ""} `}
          ></img>
        </div>
        <div className={`about-content-med ${aboutInView ? "active" : ""}`}>
          <p className="about-txt-med">Chez Careline,</p>
          <p className="about-subtxt-med">
            Nous vous accompagnont vers une pratique médicale plus efficace,
            rentable et gratifiante !
          </p>
          <div className="do-med">
            <Do title="Augmentation de votre visibilité professionnelle et de vos revenus" />
            <Do title="Gestion simplifiée de votre emploi du temps" />
            <Do title="Accès instantané aux dossiers médicaux" />
            <Do title="Prescription d'ordonnance rapide et lisible" />
          </div>
        </div>
      </section>

      <section className="feedback-section" ref={temoignageRef}>
        <Feedback title={"Démonstration"} feedbackVideo={Demo} />
        <br></br>
      </section>

      <h1 className="section-title">Nos services</h1>

      <section className="service-section " ref={RefService}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <p className={`service-txt ${serviceInView ? "active" : ""}`}>
          Careline, votre stéthoscope virtuel pour des consultations vous offres
          :
        </p>
        <div className="white">
          <Service icon={priseRDV1} service="Gestion des disponibilités" />
          <div className="visioconf-resize">
            <Service icon={priseRDV2} service="Consultation en visio" />
          </div>
          <Service icon={priseRDV4} service="Suivi du dossier médical " />
          <Service icon={priseRDV8} service="Envoi de prescriptions " />
          <Service icon={priseRDV5} service="Chat en temps réel" />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AcceuilMedecin;
