import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/componentStyle/MainMesPatients.css";
import SearchPatient from "./SearchPatient";
import CardPatient from "./CardPatient";
import { API_URL } from "../constants";

const MainDossierMedical = () => {
  const [patients, setPatients] = useState([]);
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));

  // Fetch all patients when component mounts
  useEffect(() => {
    const fetchAllPatients = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const response = await axios.get(`${API_URL}/medecin/mypatients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPatients(response.data);
      } catch (error) {
        console.error("Error fetching patients data:", error);
      }
    };
    fetchAllPatients();
  }, []);

  const handleSearchResults = (results) => {
    setPatients(results);
  };

  return (
    <div className="search-card-patient">
      <div className="title-liste-pat">
        <p>{`Les dossiers médicaux de vos patients : `}</p>
        <br></br>
        <hr></hr>
      </div>
      <br />
      <div>
        <SearchPatient onSearchResults={handleSearchResults} />
      </div>
      <div className="patients-cards">
        {patients.map((patient) => (
          <CardPatient key={patient.id} patient={patient} />
        ))}
      </div>
    </div>
  );
};

export default MainDossierMedical;
