import React, { useEffect, useState } from "react";
import styles from "../styles/componentStyle/formmaker.module.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ANNEES, MOIS, JOUR } from "../constants";
import imgIcon from "../../src/assets/icons/img.png";

const FormMaker = ({
  inputs = [],
  logo,
  headline,
  subHeadline,
  onSubmit = () => null,
  onCancel,
  onCancelLabel,
  onSubmitLabel,
  submitClassName,
}) => {
  const inputNames = {};

  inputs.forEach((input) => {
    if (Array.isArray(input)) {
      input.forEach((input) => {
        if (input.type !== "link") {
          const attribute = input.name;
          inputNames[attribute] = "";
          input.fieldName = attribute;
        }
      });
    } else {
      if (input.type !== "link") {
        const attribute = input.name;
        inputNames[attribute] = "";
        input.fieldName = attribute;
      }
    }
  });

  const [formState, setFormState] = React.useState(inputNames);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {logo}
        <h3>{headline}</h3>
        <h4>{subHeadline}</h4>
      </div>
      <div className={styles.content}>
        {inputs.map((input, index) => (
          <InputGenerator
            key={index}
            input={input}
            state={[formState, setFormState]}
          />
        ))}
      </div>
      <div className={styles.footer}>
        {onCancel && (
          <button className={styles.buttonCancel} onClick={onCancel}>
            {onCancelLabel ?? "Annuler"}
          </button>
        )}
        <button
          className={`${styles.buttonSubmit} ${submitClassName}`}
          onClick={() => onSubmit(formState)}
        >
          {onSubmitLabel ?? "S'inscrire"}
        </button>
      </div>
    </div>
  );
};

const InputGenerator = ({ input, state }) => {
  if (Array.isArray(input)) {
    return (
      <div className={styles.row}>
        {input.map((row) => (
          <InputGenerator input={row} state={state} />
        ))}
      </div>
    );
  }

  const [formState, setFormState] = state;

  const type = input.type;
  const fieldName = input.fieldName;

  if (type === "text")
    return (
      <TextInput
        {...input}
        onChange={(e) =>
          setFormState({ ...formState, [fieldName]: e.target.value })
        }
      />
    );
  if (type === "email")
    return (
      <EmailInput
        {...input}
        onChange={(e) =>
          setFormState({ ...formState, [fieldName]: e.target.value })
        }
      />
    );
  if (type === "password")
    return (
      <PasswordInput
        {...input}
        onChange={(e) =>
          setFormState({ ...formState, [fieldName]: e.target.value })
        }
      />
    );
  if (type === "select")
    return (
      <SelectInput
        {...input}
        onChange={(e) => setFormState({ ...formState, [fieldName]: e.value })}
      />
    );
  if (type === "date")
    return (
      <DateInput
        {...input}
        onChange={(e) => setFormState({ ...formState, [fieldName]: e })}
      />
    );
  if (type === "link") return <LinkInput {...input} />;
  if (type === "file")
    return (
      <ImageInput
        {...input}
        onChange={(e) => setFormState({ ...formState, [fieldName]: e })}
      />
    );

  throw new Error("[FORMMAKER]: Input not recognized.");
};

const ImageInput = ({ onChange, ...props }) => {
  const [image, setImage] = useState(imgIcon);

  const handleImageChange = (e) => {
    //Recupere du premierfichier selectionner
    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
      onChange(file);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        {...props}
        style={{ display: "none" }}
        id="file"
        onChange={handleImageChange}
      />
      <label htmlFor="file" className={styles.imageLabel}>
        {image ? (
          <img src={image} alt="Selected" className={` ${styles.smallImage}`} />
        ) : (
          <img src={image} alt="Placeholder" className={styles.imagePreview} />
        )}
        <p className={styles.labelText}>Ajouter une photo*</p>
      </label>
    </div>
  );
};

const TextInput = ({ icon, label, ...props }) => {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.iconContainer}>{icon}</div>
      <input className={styles.input} placeholder={label} {...props} />
    </div>
  );
};

const EmailInput = ({ icon, label, ...props }) => {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.iconContainer}>{icon}</div>
      <input
        className={styles.input}
        placeholder={label}
        {...props}
        type="email"
      />
    </div>
  );
};

const PasswordInput = ({ icon, label, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.iconContainer}>{icon}</div>
      <input
        className={styles.passwordInput}
        placeholder={label}
        {...props}
        type={showPassword ? "text" : "password"}
      />
      {showPassword ? (
        <FontAwesomeIcon
          className={styles.passwordEyeIcon}
          icon={faEyeSlash}
          onClick={toggleShowPassword}
        />
      ) : (
        <FontAwesomeIcon
          className={styles.passwordEyeIcon}
          icon={faEye}
          onClick={toggleShowPassword}
        />
      )}
    </div>
  );
};

const SelectInput = ({ icon, label, ...props }) => {
  return (
    <Select
      styles={{ control: (style) => ({ ...style, padding: 3 }) }}
      placeholder={label}
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

const LinkInput = ({ label, link }) => {
  return (
    <div className={styles.linkInput}>
      <Link to={link}>{label}</Link>
    </div>
  );
};

const DateInput = ({ onChange }) => {
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    onChange({
      day,
      month,
      year,
    });
  }, [day, month, year]);

  return (
    <div className={styles.dateInput}>
      <Select
        styles={{ control: (style) => ({ ...style, padding: 3, width: 150 }) }}
        placeholder={"Jour"}
        options={JOUR}
        onChange={(e) => setDay(e.value)}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <Select
        styles={{ control: (style) => ({ ...style, padding: 3, width: 150 }) }}
        placeholder={"Mois"}
        options={MOIS}
        onChange={(e) => setMonth(e.value)}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <Select
        styles={{
          control: (style) => ({ ...style, padding: 3, minWidth: 150 }),
        }}
        placeholder={"Année"}
        onChange={(e) => setYear(e.value)}
        options={ANNEES}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};

// DEAL WITH MORE INPUT TYPES EX: CHECKBOXES, RADIOS ETC.

export default FormMaker;
