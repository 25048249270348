import React from "react";
import "../../styles/componentStyle/motifForm.css";
import Motif from "../Motif/Motif";
import ModeConsultation from "../ModeConsultation/ModeConsultation";

const MotifForm = ({ form, setForm }) => {
  const { motif, mode } = form;

  return (
    <div>
      <h1 className="motif-title">Motif de consultation</h1>

      <form>
        <br></br>
        <Motif motif={motif} handleChange={(v) => setForm({ ...form, motif: v })} />
        <br></br>
        <br></br>
        <ModeConsultation mode={mode} handleChange={(v) => setForm({ ...form, mode: v })} />
      </form>
    </div>
  );
};

export default MotifForm;
