import React from "react";
import Button from "../components/Button";
import "../styles/componentStyle/Card.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  LocationOn as LocationOnIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { Star, StarHalf, StarOutline } from "@mui/icons-material";

const Card = (props) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = React.useState(false);

  const handleRDVClick = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const clonedProps = { ...props };
      delete clonedProps.onSelectDoctor;
      navigate(`/creerunrendezvous/${props.id}`, {
        state: { doctor: clonedProps },
      });
    } else {
      setShowPopup(true);
    }
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        stars.push(<Star key={i} />);
      } else if (rating >= i - 0.5) {
        stars.push(<StarHalf key={i} />);
      } else {
        stars.push(<StarOutline key={i} />);
      }
    }
    return stars;
  };

  return (
    <div
      className={`card ${props.isSelected ? "selected" : ""}`}
      onClick={props.onSelectDoctor}
    >
      <div className="top-card">
        <img src={props.image} alt="titre" className="card__image" />
        <div className="name-spec">
          <h2 className="profile-name">{props.title}</h2>
          <p className="spec">{props.subtitle}</p>
          <div className="rating-stars">{renderStars(props.meanRating)}</div>
        </div>
      </div>
      <div className="adresse-card">
        <LocationOnIcon />
        {props.adresse}
      </div>
      <Button
        customClass="card-btn"
        btnText={"Planifier un rendez-vous"}
        onClick={handleRDVClick}
        type="submit"
      />
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <CloseIcon
              className="closeIcon"
              onClick={() => setShowPopup(false)}
            />
            <h2 className="pop-header">
              Vous devez vous inscrire pour pouvoir prendre un rendez-vous
            </h2>
            <div>
              <h3>Nouveau sur Careline ?</h3>
              <button className="pop-btn">
                <Link className="pop-link" to="/inscription/patient">
                  S'inscrire
                </Link>
              </button>
              <br />
              <h3>J'ai déjà un compte sur Careline</h3>
              <button className="pop-btn">
                <Link className="pop-link" to="/authentification/patient">
                  Se connecter
                </Link>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
