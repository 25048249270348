import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/pageStyle/VisioPage.css";
import logo from "../assets/images/LogoDark.png";
import camEnabled from "../assets/icons/camera.png";
import camDisabled from "../assets/icons/cameraDisabled.png";
import leave from "../assets/icons/phone.png";
import micDisabled from "../assets/icons/micOfff.png";
import micEnabled from "../assets/icons/mic.png";
import OtherSideVisioMedecin from "../components/OtherSideVisioMedecin";
import OtherSideVisioPatient from "../components/OtherSideVisioPatient";
import { API_URL } from "../constants";
import ModalRate from "../components/ModalRate";
import useVisioconference from "../hooks/useVisioconference";

const VisioPage = ({ userType }) => {
  const [localStream, remoteStream] = useVisioconference();

  const { rendezVousId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleCamera = () => {
    if (localStream) {
      const videoTrack = localStream.getTracks().find((track) => track.kind === "video");
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        document.getElementById("camera-btn").style.backgroundColor = videoTrack.enabled ? "#93b838" : "#024631";
        const camIcon = document.getElementById("cam-icon");
        if (camIcon) {
          camIcon.src = videoTrack.enabled ? camEnabled : camDisabled;
        }
      }
    }
  };

  const toggleMicrophone = () => {
    if (localStream) {
      const audioTrack = localStream.getTracks().find((track) => track.kind === "audio");
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        document.getElementById("mic-btn").style.backgroundColor = audioTrack.enabled ? "#93b838" : "#024631";
        const micIcon = document.getElementById("mic-icon");
        if (micIcon) {
          micIcon.src = audioTrack.enabled ? micEnabled : micDisabled;
        }
      }
    }
  };

  const handleLeave = () => {
    if (userType === "patient") {
      setIsModalOpen(true);
    } else {
      navigate(userType === "medecin" ? "/medecin" : "/patient");
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/patient");
  };

  const handleSubmitRating = async (rating) => {
    const token = sessionStorage.getItem("token");
    try {
      await axios.post(
        `${API_URL}/patient/rating`,
        {
          rendezVousId,
          rating,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Rating submitted successfully!");
    } catch (error) {
      console.error("Erreur lors de l'envoi de la note :", error);
    }
  };

  return (
    <>
      <div className="header-visio">
        <img className="logo-visio" src={logo} alt="visio"></img>
      </div>
      <div className="both">
        <div className="visio-container">
          <div style={{ display: "flex" }}>
            {/* Je laisse position fixed , ou je relative */}
            <div style={{ marginTop: "20px", position: "relative" }}>
              <video
                autoPlay
                style={{
                  width: "1000px",
                  height: "700px",
                }}
                ref={(video) => {
                  if (video && remoteStream) {
                    video.srcObject = remoteStream;
                  }
                }}
              />
              {localStream && (
                <video
                  muted
                  autoPlay
                  style={{
                    width: "300px",
                    height: "150px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: "1",
                  }}
                  ref={(video) => {
                    if (video && localStream) {
                      video.srcObject = localStream;
                    }
                  }}
                />
              )}
            </div>
          </div>

          <div id="controls">
            <div className="control-container" id="camera-btn" onClick={toggleCamera}>
              <img id="cam-icon" src={camEnabled} alt="cam"></img>
            </div>

            <div className="control-container" id="mic-btn" onClick={toggleMicrophone}>
              <img id="mic-icon" src={micEnabled} alt="microphone" />
            </div>

            <div className="control-container" id="leave-btn">
              <img id="leave-icon" src={leave} alt="leave" onClick={handleLeave}></img>
            </div>
          </div>
        </div>
        <div className="observation-ordonnance">
          {userType === "medecin" ? (
            <div>
              <OtherSideVisioMedecin />
            </div>
          ) : (
            <div>
              <OtherSideVisioPatient />
            </div>
          )}
        </div>
        {isModalOpen && <ModalRate rendezVousId={rendezVousId} isOpen={isModalOpen} onClose={handleModalClose} onSubmit={handleSubmitRating} />}
      </div>
    </>
  );
};

export default VisioPage;
