import React from "react";
import MenuVisio from "./MenuVisio";

const OtherSideVisioPatient = () => {
  return (
    <div>
      <MenuVisio showPrescription={false} />
    </div>
  );
};

export default OtherSideVisioPatient;
