import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EventAvailable,
  Search as SearchIcon,
  Dashboard as DashboardIcon,
  Person as PersonIcon,
  Message as MessageChannelIcon,
  Settings as SettingsIcon,
  ExitToApp as ExitToAppIcon,
  MedicalServices as MedicalServicesIcon,
} from "@mui/icons-material";
import SideBar from "../../components/SideBar";
import "../../styles/pageStyle/AcceuilMedecin.css";
import "../../styles/pageStyle/Historique.css";
import SearchHistorique from "../../components/SearchHistorique";
import CardHistorique from "../../components/CardHistorique";
import ObservationMedView from "../../components/ObservationMedView";
import Results from "../../components/Results";
import Prescriptions from "../../components/Prescriptions";
import { API_URL } from "../../constants";
import axios from "axios";
import dayjs from "dayjs";

const Historique = ({ handleLogout }) => {
  const { id: patientId } = useParams();
  const [rendezVous, setRendezVous] = useState([]);
  const [selectedRdv, setSelectedRdv] = useState(null);
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    const confirmLogout = window.confirm(
      "Voulez vous vraimant vous déconnecter ?"
    );
    if (confirmLogout) {
      sessionStorage.removeItem("medecin");
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      handleLogout();
      navigate("/");
    }
  };

  useEffect(() => {
    document.body.classList.add("portal");

    return () => {
      document.body.classList.remove("portal");
    };
  }, []);

  useEffect(() => {
    const fetchConsultations = async () => {
      const token = sessionStorage.getItem("token");

      try {
        const response = await axios.get(
          `${API_URL}/medecin/rendezvous/date/${patientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.map((rdv) => ({
          id: rdv.id,
          patient: {
            nom: rdv.patient.nom,
            prenom: rdv.patient.prenom,
          },
          motif:
            rdv.motif.toLowerCase() === "premierefois"
              ? "Premiere fois"
              : "Suivi",
          date: dayjs(rdv.date).format("DD/MM/YYYY"),
          heure: rdv.heure,
          mode: rdv.mode.toLowerCase() === "video" ? "Video" : "Cabinet",
        }));

        setRendezVous(data);
        setSelectedRdv(data[0]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConsultations();
  }, []);

  const handleRdvClick = (rdv) => {
    if (rdv.id) {
      setSelectedRdv(rdv);
      // fetchFiles(rdv.id);
    } else {
      console.error("rdv.id is undefined");
    }
  };

  const listItems = [
    {
      text: "Mes consultations",
      icon: <DashboardIcon />,
      link: "/medecin",
    },
    {
      text: "Mes patients",
      icon: <PersonIcon />,
      link: "/medecin/mespatients",
    },
    {
      text: "Mes disponibilités",
      icon: <EventAvailable />,
      link: "/availabilities",
    },
    {
      text: "Dossiers medicaux",
      icon: <MedicalServicesIcon />,
      link: "/medecin/dossiersmedicaux",
    },
    { text: "Messagerie", icon: <MessageChannelIcon />, link: "/chat" },
    { text: "Paramètres", icon: <SettingsIcon />, link: "/medecin/profil" },
    {
      text: "Se déconnecter",
      icon: <ExitToAppIcon />,
      link: "/",
      onClick: handleLogoutClick,
    },
  ];

  const imageURL = medecin.image
    ? `${API_URL}${medecin.image}`
    : "https://picsum.photos/300/200";

  return (
    <div>
      <div className="set-portal-grid-historique">
        <div>
          <SideBar listItems={listItems} />
        </div>
        <div className="main-hist">
          <div>
            <h4 className="hsitorique-title">Historique :</h4>
            <SearchHistorique setRendezVous={setRendezVous} />
            {rendezVous.map((rdv) => (
              <div key={rdv.id} onClick={() => handleRdvClick(rdv)}>
                <p className="hist-text">{`${rdv.date}, ${rdv.heure}`}</p>
                <CardHistorique
                  rdv={rdv}
                  isSelected={selectedRdv && selectedRdv.id === rdv.id}
                />
              </div>
            ))}
          </div>
          <div className="observation-middle">
            {selectedRdv && <ObservationMedView rdv={selectedRdv} />}
          </div>
          <div>
            {selectedRdv && <Prescriptions rdv={selectedRdv} />}
            {selectedRdv && <Results rdv={selectedRdv} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Historique;
