import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import axios from "axios";
import { API_URL } from "../constants";

const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "20px",
      marginLeft: "50px",
      width: "355px",
    },
  });
});

const SearchPatient = ({ onSearchResults }) => {
  const { search } = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowClearIcon(value === "" ? "none" : "flex");
  };

  const handleSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}/medecin/mypatients`,
        {
          params: { name: searchTerm },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      onSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  const handleClick = () => {
    setSearchTerm("");
    setShowClearIcon("none");
  };

  return (
    <div id="app">
      <FormControl className={search}>
        <TextField
          size="small"
          placeholder="Rechercher par le nom ou prenom"
          variant="outlined"
          value={searchTerm}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon onClick={handleSearch} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

export default SearchPatient;
