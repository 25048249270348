import React from "react";
import "../styles/componentStyle/MainDash.css";
import Table from "./Table/Table";

const MainDashMedecin = () => {
  const patient = JSON.parse(sessionStorage.getItem("patient"));

  //string.charAt(0).toUpperCase() ===>"bonjour", cela devient "B" + string.slice(1) ===> "onjour", ce qui donne "Bonjour".
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div className="MainDash">
      <h1 className="txt-portal">
        Ravi de vous revoir, {capitalizeFirstLetter(patient.civilite)}{" "}
        {capitalizeFirstLetter(patient.nom)}{" "}
        {capitalizeFirstLetter(patient.prenom)} !
      </h1>
      <br></br>
      <br></br>
      <Table />
    </div>
  );
};

export default MainDashMedecin;
