import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import axios from "axios";
import { API_URL } from "../constants";

const Results = ({ rdv }) => {
  const token = sessionStorage.getItem("token");

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const ScrollableList = styled(List)(({ theme }) => ({
    maxHeight: "600px",
    overflowY: "auto",
  }));

  const retrieveDoc = async () => {
    try {
      const response = await axios.get(`${API_URL}/medecin/files/${rdv.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.file;
    } catch (error) {
      return { filename: [], url: [] };
    }
  };

  const [files, setFiles] = useState({ filename: [], url: [] });

  useEffect(() => {
    const fetchFiles = async () => {
      const retrievedFiles = await retrieveDoc();
      setFiles(retrievedFiles);
    };
    fetchFiles();
  }, [rdv.id]);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <Grid container spacing={2}>
        <div className="result-title">
          <h4 className="observation-title">Résultats des tests :</h4>
        </div>
        <Grid item xs={12} md={10}>
          {files.filename.length === 0 ? (
            <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
              Aucun document envoyé encore
            </Typography>
          ) : (
            <Demo>
              <ScrollableList>
                <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div"></Typography>
                <List>
                  {files.filename.map((filename, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <ListItemAvatar>
                        <FolderIcon style={{ color: "green" }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={filename}
                        secondary={files.filename[index]}
                        onClick={() => window.open(`${API_URL}${files.url[index]}`, "_blank")}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </ScrollableList>
            </Demo>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Results;
