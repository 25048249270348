import React from "react";
import ListDoc from "./ListDoc";

const DocMedecin = () => {
  return (
    <div>
      <ListDoc />
    </div>
  );
};

export default DocMedecin;
