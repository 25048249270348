import React from "react";
import RDVCard from "./RDVCard";

const Doctor = ({ doctor }) => {
  return (
    <section>
      <RDVCard
        title={doctor.title}
        subtitle={doctor.subtitle}
        adresse={doctor.adresse}
        image={doctor.image}
      />
    </section>
  );
};

export default Doctor;
