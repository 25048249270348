import React from "react";
import Card from "../components/Card";
import { API_URL } from "../constants";

const Doctors = ({ doctors, onSelectDoctor, selectedDoctorId }) => {
  return (
    <section className="card-container">
      {doctors.map((doctor) => (
        <Card
          key={doctor.id} //those 2 lines
          id={doctor.id} //afin de pouvoir faire passer les donnees du medecin selectionner uniquement
          title={`Dr ${doctor.prenom} ${doctor.nom}`}
          subtitle={doctor.specialite}
          adresse={doctor.adresse}
          image={
            doctor.image
              ? `${API_URL}${doctor.image}`
              : "https://picsum.photos/300/200"
          } // Ensure the full URL is passed
          onSelectDoctor={() => onSelectDoctor(doctor.id)}
          isSelected={doctor.id === selectedDoctorId} // Pass selected state
          meanRating={doctor.meanRating}
        />
      ))}
    </section>
  );
};

export default Doctors;
