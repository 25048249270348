import React, { useState } from "react";
import "../styles/componentStyle/ModalRate.css";
import { Close as CloseIcon } from "@mui/icons-material";

const ModalRate = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(0);

  const handleRatingClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = () => {
    onSubmit(rating);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="rating-modal">
      <div className="modal-content">
        <br></br>
        <div className="close-modal">
          <h2>Merci de laisser votre avis sur le médecin</h2>
          <CloseIcon className="close-rate" onClick={onClose}></CloseIcon>
        </div>

        <div className="stars">
          {[...Array(5)].map((_, i) => (
            <span
              key={i}
              className={i < rating ? "star filled" : "star"}
              onClick={() => handleRatingClick(i)}
            >
              &#9733;
            </span>
          ))}
        </div>
        <button onClick={handleSubmit} className="rate-submit">
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default ModalRate;
