import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileRating from "../../components/ProfileRating";
import { EventAvailable, Search as SearchIcon } from "@mui/icons-material";
import SideBar from "../../components/SideBar";
import "../../styles/pageStyle/AcceuilMedecin.css";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { Person as PersonIcon } from "@mui/icons-material";
import { MedicalServices as MedicalServicesIcon } from "@mui/icons-material";
import { Message as MessageChannelIcon } from "@mui/icons-material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { ExitToApp as ExitToAppIcon } from "@mui/icons-material";
import MainDossierMedical from "../../components/MainDossierMedical";
import { API_URL } from "../../constants";

//Avant c'etait mes patients
const DossierMedical = ({ handleLogout }) => {
  const medecin = JSON.parse(sessionStorage.getItem("medecin"));
  const navigate = useNavigate();
  const medecinId = medecin.id;

  const handleLogoutClick = () => {
    const confirmLogout = window.confirm(
      "Voulez vous vraimant vous déconnecter ?"
    );
    if (confirmLogout) {
      sessionStorage.removeItem("medecin");
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      handleLogout();
      navigate("/");
    }
  };
  useEffect(() => {
    document.body.classList.add("portal");

    return () => {
      document.body.classList.remove("portal");
    };
  }, []);

  const listItems = [
    {
      text: "Mes consultations",
      icon: <DashboardIcon />,
      link: "/medecin",
    },
    {
      text: "Mes patients",
      icon: <PersonIcon />,
      link: "/medecin/mespatients",
    },

    {
      text: "Mes disponibilités",
      icon: <EventAvailable />,
      link: "/availabilities",
    },
    {
      text: "Dossiers medicaux",
      icon: <MedicalServicesIcon />,
      link: "/medecin/dossiersmedicaux",
    },

    { text: "Messagerie", icon: <MessageChannelIcon />, link: "/chat" },

    { text: "Paramètres", icon: <SettingsIcon />, link: "/medecin/profil" },
    {
      text: "Se déconnecter",
      icon: <ExitToAppIcon />,
      link: "/",
      onClick: handleLogoutClick,
    },
  ];

  const imageURL = medecin.image
    ? `${API_URL}${medecin.image}`
    : "https://picsum.photos/300/200";

  return (
    <div>
      <div className="set-portal-grid">
        <div>
          <SideBar listItems={listItems} />
        </div>
        <div className="main-dash">
          <MainDossierMedical />
        </div>
        {imageURL ? (
          <ProfileRating
            showRating={true}
            profileImage={imageURL}
            name={`${medecin.nom} ${medecin.prenom}`}
            role={`${medecin.specialite}`}
            specialite={`${medecin.specialite}`}
            id={medecinId}
          />
        ) : (
          <p>Image not available</p>
        )}
      </div>
    </div>
  );
};

export default DossierMedical;
