import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { shouldDisableDate } from "../../utils/day";

export default function DateCalendarValue({
  form,
  setForm,
  disponibilite,
  rendezvous,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <DemoContainer components={["DateCalendar", "DateCalendar"]}>
        <DemoItem>
          <DateCalendar
            disablePast
            shouldDisableDate={(day) =>
              shouldDisableDate(day, disponibilite, rendezvous)
            }
            value={form.date}
            onChange={(newValue) => setForm({ ...form, date: newValue })}
            sx={{
              ".MuiDateCalendar-root": {
                transform: "scale(2.1)", // Adjust the scale to enlarge the calendar
                transformOrigin: "top left", // Ensure the transformation origin is the top left corner
                border: "2px solid #ccc", // Add a border
                borderRadius: "8px", // Add rounded corners
              },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
