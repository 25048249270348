import React from "react";
import { Link } from "react-router-dom";
import "../styles/componentStyle/Button.css";

function Button({ btnLink, btnText, customClass, type, onClick }) {
  customClass = `button ${customClass ? customClass : ""}`;

  if (type && onClick) {
    return (
      <button type={type} className={customClass} onClick={onClick}>
        {btnText}
      </button>
    );
  }

  const testLink = btnLink || "/test";

  return (
    <Link to={testLink} className={customClass}>
      {btnText}
    </Link>
  );
}

export default Button;
