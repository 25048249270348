import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { privateRoutes, publicRoutes } from "../utils/routes";

const CarelineRoutes = () => {
  const [isAuthen, setIsAuthen] = useState(false);

  const handleLogout = () => {
    setIsAuthen(false);
  };

  const handleLogin = () => {
    setIsAuthen(true);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const medecin = sessionStorage.getItem("medecin");
    const patient = sessionStorage.getItem("patient");

    if ((token && medecin) || (token && patient)) setIsAuthen(true);
  }, []);

  return (
    <Routes>
      {publicRoutes(handleLogin).map((route) => (
        <Route key={route.path} {...route} />
      ))}

      {isAuthen &&
        privateRoutes(handleLogout).map((route) => (
          <Route key={route.path} {...route} />
        ))}
    </Routes>
  );
};

const CarelineRouter = () => {
  return (
    <Router>
      <CarelineRoutes />
    </Router>
  );
};

export default CarelineRouter;
