import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { API_URL } from "../../src/constants";
import "../styles/componentStyle/MainPrescriptions.css";

const MainPrescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const patient = JSON.parse(sessionStorage.getItem("patient"));
  const patientId = patient.id;

  const retrievePrescriptions = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        `${API_URL}/patient/prescriptions/${patientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        setPrescriptions(response.data);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des prescriptions", error);
    }
  };

  useEffect(() => {
    retrievePrescriptions();
  }, []);

  return (
    <div>
      <div className="title-liste-pat">
        <h3>Mes prescriptions</h3>
        <br></br>
        <hr></hr>
      </div>
      <div className="grid-prescriptions">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            {prescriptions.map((prescription, index) => (
              <Grid item xs={12} md={6} key={index}>
                <h3>
                  {prescription.type} -{" "}
                  {new Date(prescription.date).toLocaleDateString()}
                </h3>
                <br></br>
                <iframe
                  src={`${API_URL}${prescription.file}`}
                  title={`${prescription.type}-${index}`}
                  width="85%"
                  height="600px"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default MainPrescriptions;
