import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { API_URL } from "../constants";

const Prescriptions = ({ rdv }) => {
  const [files, setFiles] = useState({
    prescriptionPath: null,
    bilanPath: null,
  });

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/medecin/prescriptions/${rdv.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFiles(response.data.files);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    if (rdv && rdv.id) {
      fetchFiles();
    }
  }, [rdv]);

  function generate(url, label) {
    return url ? (
      <ListItem sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
        <FontAwesomeIcon
          icon={faPaperclip}
          style={{
            color: "green",
            marginLeft: "10px",
            marginRight: "30px",
            height: "20px",
          }}
        />
        <ListItemText
          primary={label}
          primaryTypographyProps={{ style: { color: "black" } }}
          secondary={url.split("/").pop()}
          onClick={() => window.open(`${API_URL}${url}`, "_blank")}
          style={{
            cursor: "pointer",
          }}
        />
      </ListItem>
    ) : null;
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  const ScrollableList = styled(List)(({ theme }) => ({
    maxHeight: "600px",
    overflowY: "auto",
  }));

  return (
    <div>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <div className="result-title">
              <h4 className="observation-title">Prescriptions :</h4>
            </div>
            <Demo>
              {!files.prescriptionPath && !files.bilanPath ? (
                <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
                  Aucune prescription trouvée
                </Typography>
              ) : (
                <ScrollableList>
                  <List>
                    {generate(files.prescriptionPath, "Ordonnance")}
                    {generate(files.bilanPath, "Bilan")}
                  </List>
                </ScrollableList>
              )}
            </Demo>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Prescriptions;
