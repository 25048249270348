import React from "react";
import moment from "moment";
import "./Message.css";

const Message = ({ message }) => {
  const userType = sessionStorage.getItem("userType"); // Get the current user type
  const userData = JSON.parse(sessionStorage.getItem(userType));
  const userId = userData.id;

  // Format timestamp
  const formatTimestamp = (timestamp) => {
    return moment(timestamp).format("HH:mm");
  };

  return (
    <div className={`message ${message.senderId === userId ? "owner" : ""}`}>
      <div className="messageContent">
        <p className="dm-text">{message.content}</p>
      </div>
      <div className="messageInfo">
        <span className="maintenant">{formatTimestamp(message.timestamp)}</span>
      </div>
    </div>
  );
};

export default Message;
