import React, { useState } from "react";

const InputChat = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <div className="input-chat">
      <input
        type="text"
        placeholder="Ecris ton message ici..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="send">
        <button className="send-btn" onClick={handleSendMessage}>
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default InputChat;
