import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import "../../styles/pageStyle/AuthenPatient.css";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormMaker from "../../components/FormMaker";
import { API_URL } from "../../constants";

const AuthentificationPatient = ({ handleLogin }) => {
  useEffect(() => {
    document.body.classList.add("signin-pat");

    return () => {
      document.body.classList.remove("signin-pat");
    };
  }, []);

  const Navigate = useNavigate();

  async function submit(e) {
    const email = e.emailOrPhoneNumber;
    const telephone = e.emailOrPhoneNumber;
    const password = e.password;

    try {
      const response = await axios.post(
        `${API_URL}/patient/authentification/patient`,
        {
          email,
          telephone,
          password,
        }
      );
      const token = response.data.token;
      localStorage.setItem("token", token);
      console.log("Voici les donnees response.data", response.data);
      const userData = response.data;
      sessionStorage.setItem("token", userData.token);
      sessionStorage.setItem("patient", JSON.stringify(userData.patient));
      sessionStorage.setItem("userType", "patient");

      handleLogin();
      toast.success("Connexion réussie !");
      setTimeout(() => {
        Navigate("/patient");
      }, 500);
    } catch (e) {
      console.log(e);
      toast.error("Informations Incorrects !");
    }
  }

  return (
    <div className="auth-form-container">
      <FormMaker
        onSubmit={submit}
        headline={"Bienvenue sur Careline !"}
        subHeadline={"Saisissez vos informations pour continuer."}
        logo={<img src={logo} alt="logo" className="logo-form" />}
        inputs={[
          {
            icon: <FontAwesomeIcon icon={faUser} />,
            name: "emailOrPhoneNumber",
            label: "Email ou numéro de téléphone",
            type: "text",
          },
          {
            icon: <FontAwesomeIcon icon={faKey} />,
            name: "password",
            label: "Mot de passe",
            type: "password",
          },
          {
            name: "loginLink",
            label: "Vous etes nouveau ? Inscrivez-vous",
            link: "/inscription/patient",
            type: "link",
          },
        ]}
        onSubmitLabel={"Connexion"}
      />
    </div>
  );
};

export default AuthentificationPatient;
