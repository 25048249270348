import React, { useEffect, useState, useRef } from "react";
import SearchBar from "../../components/SearchBar";
import "../../styles/pageStyle/findyourdoctor.css";
import Doctors from "../../components/Doctors";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

// Helper component, il recentre la carte sur le selected Dr
const MapHelper = ({ selectedDoctor }) => {
  const map = useMap(); //Hook pour acceder a l'instance de la carte Leaflet

  useEffect(() => {
    //Effet qui se declenche lorsque il selectionne un medecin
    if (selectedDoctor) {
      map.setView([selectedDoctor.lat, selectedDoctor.lon], 13); //1 = Niveau de Zoom
    }
  }, [selectedDoctor, map]);

  return null;
};

const FindYourDoctor = () => {
  //Icon du marker
  const customIcon = new Icon({
    iconUrl:
      "https://img.icons8.com/?size=100&id=13800&format=png&color=000000",
    iconSize: [38, 38],
  });

  const [doctors, setDoctors] = useState([]);
  const [markers, setMarkers] = useState([]); //stocker les marqueurs avec leurs coordonnées géographiques
  const location = useLocation(); //obtenir des infos sur l'url actuelle
  const token = localStorage.getItem("token");

  // afficher popup du médecin sélectionné
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const markerRefs = useRef({});

  //Fonction de Geocodage
  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search`,
        {
          //Les paramètres de la requête sont passés dans l'objet params :
          params: {
            q: address,
            format: "json",
          },
        }
      );
      if (response.data && response.data.length > 0) {
        //verifie s'il existe et s'il contient au moins un element
        const { lat, lon } = response.data[0];
        return { lat, lon };
      }
      return null;
    } catch (error) {
      console.error("Erreur lors du géocodage de l'adresse:", error);
      return null;
    }
  };

  //useEffect est utilisé pour effectuer des actions au moment du montage du composant et chaque fois que la dépendance location.search change
  useEffect(() => {
    const fetchDoctors = async () => {
      const params = new URLSearchParams(location.search); //URLSearchParams est utilisé pour extraire les paramètres de requête de l'URL.
      const query = params.get("query") || ""; //query et filter sont extraits des paramètres de l'URL
      const filter = params.get("filter") || "Tout";

      try {
        const response = await axios.get(API_URL + "/patient/findyourdoctor", {
          params: { query, filter },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const doctorsData = response.data;
        setDoctors(doctorsData); //Recupere et stocke les medecins

        // Géocoder les adresses des médecins
        const markersData = await Promise.all(
          //Promise.all est utilisé pour attendre que toutes les promesses de géocodage soient résolues.
          doctorsData.map(async (doctor) => {
            const coordinates = await geocodeAddress(doctor.adresse);
            if (coordinates) {
              return {
                ...coordinates,
                popUp: `Dr ${doctor.prenom} ${doctor.nom}`,
                id: doctor.id,
              };
            }
            return null;
          })
        );

        // Filtrer les résultats nuls (échecs de géocodage)
        setMarkers(markersData.filter((marker) => marker !== null));
      } catch (error) {
        console.error("Erreur lors de la récupération des médecins:", error);
      }
    };

    fetchDoctors();

    return () => {};
  }, [location.search, token]); //Ce hook s'exécute chaque fois que location.search change.

  useEffect(() => {
    //est utilisé pour effectuer des actions au moment du montage du composant et chaque fois que la dépendance selectedDoctorId change.
    if (selectedDoctorId && markerRefs.current[selectedDoctorId]) {
      //Vérifie si selectedDoctorId n'est pas nul et si une référence au marqueur correspondant existe dans markerRefs.current.
      markerRefs.current[selectedDoctorId].openPopup(); //markerRefs est un objet ref qui est utilisé pour accéder directement aux instances des marqueurs Leaflet.
    }
  }, [selectedDoctorId]);

  return (
    <div className="find-doctor-container">
      <div className="search-map">
        <SearchBar placeholder="Rechercher tout" customClass="home-searchbar" />
      </div>
      <div className="doctor-map">
        <div className="find-doctor-right">
          <div className="nav-find-dr">
            <br></br>
            <Doctors
              doctors={doctors}
              onSelectDoctor={setSelectedDoctorId}
              selectedDoctorId={selectedDoctorId} // Pass the selectedDoctorId
            />
          </div>
        </div>
        <div className="map-side">
          <MapContainer
            center={[36.7118, 4.0459]}
            zoom={13}
            style={{ height: "100vh", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup>
              {markers.map((marker) => (
                <Marker
                  key={marker.id}
                  position={[marker.lat, marker.lon]}
                  icon={customIcon}
                  ref={(el) => (markerRefs.current[marker.id] = el)}
                >
                  <Popup>
                    <h3>{marker.popUp}</h3>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
            <MapHelper
              selectedDoctor={markers.find(
                (marker) => marker.id === selectedDoctorId
              )}
            />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default FindYourDoctor;
